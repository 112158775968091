/* Lesson - Blocks */

.lesson .block-wrapper {
	display: flex;
	gap: var(--padding-150);
	flex-wrap: wrap;
}

.lesson .block {
	border-radius: var(--border-radius-150);
	background: var(--card-background);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
	display: flex;
	flex-direction: column;
}

@media only screen and (max-width: 600px) {
	.lesson .block.verb  {	
		padding: var(--padding-50);
	}
}

.lesson .block.skeleton {
	height: 215px;
}

.block-content-wrapper {
	align-items: flex-start;
	align-self: stretch;
	display: flex;
	flex-direction: row;
	justify-content: left;
	padding: var(--padding-300);
	gap: var(--padding-200);
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.lesson .block-content-wrapper {		
		flex-direction: column;
		gap: var(--padding-200);
		padding: var(--padding-150);
	}
}

.block-audio-controls {
	border-top: 1px solid var(--card-divider-border);
	display: flex;
	justify-content: flex-end;
	padding: var(--padding-50) var(--padding);
	width: 100%;
}

.conversation .lesson .block.skeleton {
	height: 600px;
}

.lesson .block.hero {
	align-items: center;
	flex-direction: column;
	gap: var(--padding);
	justify-content: center;
	padding: var(--padding-200);
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.lesson .block.hero {	
		max-width: calc((100% / 2) - (var(--padding)));
		padding: var(--padding-150);
		width: auto;
	}
}

.lesson .block.hero p {
	font-size: var(--font-size-400);
	line-height: var(--font-size-400);
	text-align: center;
}

@media only screen and (max-width: 600px) {
	.lesson .block.hero p {	
		font-size: var(--font-size-300);
		line-height: var(--font-size-300);
	}
}

.lesson .block.conversation {
	align-items: center;
	border-radius: var(--border-radius-200);
	flex-direction: column;
	gap: var(--padding-200);
	padding: var(--padding-300);
}

@media only screen and (max-width: 600px) {
	.lesson .block.conversation  {		
		gap: var(--padding);
		padding: var(--padding-150);
	}
}

.lesson .block.conversation h1 {
	background: transparent;
	color: var(--body-text-color);
	padding: 0;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.lesson .block.conversation h1 {		
		font-size: var(--font-size-200);
		line-height: auto;
	}
}

.lesson .block.conversation p {
	font-size: var(--font-size-225);
	letter-spacing: -1%;
	line-height: var(--font-size-250);
	padding: var(--padding-50);
}

@media only screen and (max-width: 600px) {
	.lesson .block.conversation p  {		
		font-size: var(--font-size-150);
		line-height: auto;
		padding: var(--padding-25);
	}
}

.lesson .block.conversation .content,
.lesson .block.verb .content {
	font-size: var(--font-size-225);
	display: flex;
	flex-direction: column;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.lesson .block.conversation .content,
	.lesson .block.verb .content {		
		font-size: var(--font-size-125);
		line-height: auto;
		padding: var(--padding-25);
	}
}

.lesson .block.conversation .content table,
.lesson .block.conversation .verb table {
	border-collapse: collapse;
}

.lesson .block.verb .paradigm-pronoun {
	width: 50%;
}

@media only screen and (max-width: 600px) {
	.lesson .block.verb .paradigm-pronoun {
		width: 60%;
	}
}

.lesson .block.verb .paradigm-verbform {
	width: 50%;
}

@media only screen and (max-width: 600px) {
	.lesson .block.verb .paradigm-verbform  {
		width: 40%;
	}
}

.lesson .block.conversation .content table td {
	vertical-align: top;
}

.lesson .block.conversation .content table th {
	color: var(--grey);
	font-weight: bold;
	text-align: right;
	padding: 0 var(--padding-200);
	vertical-align: top;
}

@media only screen and (max-width: 600px) {
	.lesson .block.conversation .content table th  {		
		padding: 0 var(--padding-50);
	}
}

.lesson .block.conversation .audio-player {
	align-items: center;
	background:  var(--charcoal);
	border-radius: var(--border-radius);
	box-shadow: 0px 1px 2px 0px rgba(28, 30, 33, 0.25);
	color: var(--white);
	font-size: var(--font-size-75);
	font-weight: bold;
	gap: var(--padding-150);
	display: flex;
	padding: var(--padding);
	width: 100%;
}

.lesson .block.conversation .audio-player button {
	background: transparent;
	padding: 0;
	width: 24px;
	height: 24px;
	text-align: center;
}

.lesson .block.conversation .audio-player button:hover path {
	fill: var(--orange);
}

.lesson .block.conversation .audio-player .progress {
	align-items: center;
	display: flex;
	gap: var(--padding);
	flex-grow: 1;
}

@keyframes throb {
	0% {
		background-color: var(--blue);
	}
	50% {
		background-color: var(--orange);
	}
	100% {
		background-color: var(--blue);
	}
}

.lesson .block.vocab img {
	flex: 0 0 128px;
	max-width: 128px;
}

.lesson .block.vocab img.medium {
	flex: 0 0 64px;
	max-width: 64px;
}

.lesson .block.vocab img.small {
	flex: 0 0 32px;
	max-width: 32px;
}

.lesson .block.vocab .content {		
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: var(--padding-200);
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.lesson .block.vocab .content  {	
		flex-direction: column;		
		justify-content: center;	
		gap: var(--padding);
	}
}

.lesson .block.vocab .content.center {	
	align-items: center;
}

.lesson .block.vocab .content.bottom {	
	align-items: flex-end;
}

.lesson .block.vocab .content.baseline {	
	align-items: stretch;
}

.lesson .block.vocab .content .col {
	display: flex;		
	flex: 1;
	flex-direction: column;
	gap: var(--padding-200);
}

@media only screen and (max-width: 600px) {
	.lesson .block.vocab .content .col  {	
		width: 100%;
		justify-content: center;
		gap: var(--padding);
	}
}

.lesson .block.vocab .content .col.to-fit {
	flex: unset;
}

.lesson .block.vocab .content .col p {
	font-size: var(--font-size-150);
	line-height: var(--line-height-150);
	margin: 0;
	padding: 0;
}

.lesson .block.vocab .content .col h3 {
	background: var(--orange);
	color: var(--white);
	font-size: var(--font-size-200);
	line-height: var(--font-size-225);
	padding: 0 var(--padding-50);
	margin: 0 0 var(--padding) 0;
}

.lesson .block.vocab .content .row {
	align-items: center;
	display: flex;
	flex: 1; 
	flex-direction: row;
	gap: var(--padding-150);
	min-width: auto;
	padding: 0;
}

.lesson .block.vocab .content .row.padded {
	padding-top: var(--padding-150);
}

.lesson .block.vocab .content .row.centred {
	align-items: center !important;
	text-align: center;
}

.lesson .block.vocab .content .row.as-column {
	align-items: flex-start;
	flex-direction: column;
}

.lesson .block.verb .content table th.padded,
.lesson .block.verb .content table td.padded {
	padding-top: var(--padding-200);
}

@media only screen and (max-width: 600px) {
	.lesson .block.verb .content table th.padded,
	.lesson .block.verb .content table td.padded {
		padding-top: var(--padding);
	}
}

.lesson .block.verb .content table td,
.lesson .block.verb .content table th {
	padding: var(--padding) var(--padding-150);
}

@media only screen and (max-width: 600px) {
	.lesson .block.verb .content table td,
	.lesson .block.verb .content table th {
		padding: var(--padding);
	}
}

.lesson .block.verb .content table th {
	text-align: right;
}

.lesson .block.verb .content table th.infinitive {
	font-weight: inherit;
	text-align: center;
	width: 55%;
}

@media only screen and (max-width: 600px) {
	.lesson .block.verb .content table th.infinitive {
		width: 40%;
	}
}

.lesson .block.verb .content table td {
	color: var(--orange);
	font-weight: bold;
}


/* Exercises */

.lesson .block.exercise {
	padding: 0;
	flex-direction: column;
	gap: 0;
	overflow: hidden;
}

.lesson .exercise .exercise-answer {
	border-radius: var(--border-radius-50);
	display: block;
	height: 36px;
	margin: var(--padding) 0;
	min-width: 300px;
}

.lesson .exercise .exercise-answer.skeleton {
	min-width: 600px;
}

@media only screen and (max-width: 600px) {
	.lesson .exercise .exercise-answer.skeleton {	
		min-width: 240px;
		width: 100%;
	}
}

.lesson .exercise-wrapper {
	display: flex;
	flex-direction: column;
	gap: var(--padding-150);
}

.lesson .exercise .intro {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: var(--padding-200) var(--padding-300);
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.lesson .exercise .intro {	
		padding: var(--padding-150);
	}
}

.lesson .exercise .intro .instructions {
	display: flex;
	flex-direction: column;
	gap: var(--padding-50);
}

.lesson .exercise ol {	
	margin: var(--padding) auto;
	padding: var(--padding-200) var(--padding-300);
}

.lesson .exercise ol.chips {
	display: flex;
	flex-direction: column;
	gap: var(--padding-150);
	max-width: 100%;
}

@media only screen and (max-width: 600px) {
	.lesson .exercise ol {	
		padding: var(--padding-150);
		width: 100%;
	}
}

.lesson .exercise ol li {
	padding: var(--padding-50) 0;
	font-size: var(--font-size-125);
	display: flex;
	flex-direction: row;
	gap: var(--padding);
	align-items: center;
}

@media only screen and (max-width: 600px) {
	.lesson .exercise ol li {	
		align-items: baseline;
		max-width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.lesson .exercise ol li div {	
		white-space: nowrap;
	}
}

.lesson .exercise ol li.image-question {
	align-items: flex-start;
	background: var(--table-row-even-background);
	border-radius: var(--border-radius);
	gap: var(--padding-200);
	margin: var(--padding);
	padding: var(--padding-150);
}

@media only screen and (max-width: 600px) {
	.lesson .exercise ol li.image-question {	
		flex-direction: column;
	}
}

.lesson .exercise ol li.chip-row {
	align-items: baseline;
}

.lesson .exercise ol li .numeral {
	font-weight: bold;
	min-width: 24px;
	text-align: right;
}

@media only screen and (max-width: 600px) {
	.lesson .exercise ol li .numeral {	
		text-align: left;
	}
}

.lesson .exercise ol li .question-wrapper {
	display: flex;
	flex-direction: column;
	gap: var(--padding-200);
}

.lesson .exercise .question-content {
	align-items: baseline;
	display: flex;
	width: 100%;
}

.lesson .exercise .question-content.gapfill {
	align-items: center;
	flex-wrap: wrap;
	gap: 4px;
}

.lesson .exercise ol li .question-content.vertical {
	flex-direction: column;
	gap: var(--padding-50);
}

.lesson .exercise ol li .question-images {
	display: flex;
	gap: var(--padding-300);
}

.lesson .exercise ol li .prompt {
	align-items: center;
	display: flex;
	gap: 6px;
	max-width: 100%;
}

@media only screen and (max-width: 600px) {
	.lesson .exercise ol li .prompt {
		width: 100%;
		flex-wrap: wrap;
	}
}

.lesson .exercise ol li .chips {
	display: flex;
	flex-wrap: wrap;
	gap: var(--padding-25);
	min-height: 38px;
}

.lesson .exercise .chip {
	background: var(--card-background);
	border: 1px solid var(--grey-50);
	border-radius: var(--border-radius-50);
	box-shadow: 0 1px 1px rgb(210, 210, 211, .4);
	padding: var(--padding-25) var(--padding-50);
	transition: var(--transition);
}

.lesson .exercise .chip:hover {
	background: var(--link-highlight-color);
	border: 1px solid var(--link-color);
	color: var(--link-color);
	cursor: pointer !important;
}

.lesson .exercise ol li.correct .chip {
	border: none;
	box-shadow: none;
	padding: 0 1px;
}

.lesson .exercise ol li.correct .chip:hover {
	background: var(--card-background);
	border: none;
	color: var(--body-text-color);
	cursor: default;
}

.lesson .exercise ol li .chips-well {
	align-items: center;
	border: 1px solid var(--grey-50);
	border-radius: var(--border-radius);
	display: flex;
	gap: var(--padding-25);
	justify-content: space-between;
	min-height: 47px;
	min-width: 400px;
	padding: var(--padding-25);
}

@media only screen and (max-width: 600px) {
	.lesson .exercise ol li .chips-well {
		min-width: 100%;
		max-width: 100%;
	}
}

.lesson .exercise ol li .chips-well .chips-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: var(--padding-25);
}

.lesson .exercise ol li.correct .chips-well {
	border: 1px solid var(--turquoise);
}

.lesson .exercise ol li.correct .chips {
	min-height: 0;
	transition: var(--transition);
}

.lesson .exercise ol li.error .chips-well {
	border: 1px solid #ec1801;
}

table.exercise-gapfill {
	font-size: var(--font-size-125);
	margin: var(--padding) auto;
}

@media only screen and (max-width: 600px) {
	table.exercise-gapfill tbody {
		max-width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	table.exercise-gapfill tr {
		align-items: baseline;
		display: flex;
		max-width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	table.exercise-gapfill {
		align-items: baseline;
		display: flex;
		margin: 0 var(--padding-50);
		overflow: hidden;
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	table.exercise-gapfill th {
		overflow: hidden;
		text-overflow: ellipsis;
		width: 22% !important;
	}
}

@media only screen and (max-width: 600px) {
	table.exercise-gapfill td {
		padding: 0 !important;
		width: 78% !important;
	}
}

table.exercise-gapfill th,
table.exercise-gapfill td {
	padding: var(--padding-50) var(--padding);
	min-height: 50px;
}

table.exercise-gapfill th {
	text-align: right;
}

@media only screen and (max-width: 600px) {
	table.exercise-gapfill th {
		padding-top: 15px;
		text-align: left;
		vertical-align: top;
	}
}

table.exercise-gapfill td {
	align-items: center;
	display: flex;
	gap: 6px;
}

table.exercise-gapfill td .conversation-gapfill-content {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	padding: var(--padding-25);
	max-width: 100%;
}

@media only screen and (max-width: 600px) {
	.answer-field-wrapper {
		max-width: 90%;
	}
}

@media only screen and (max-width: 600px) {
	ol.exercise-gapfill {
		margin: 0 !important;
		max-width: 100%;
		padding: 0 !important;
	}
}

ol.exercise-gapfill li {
	margin: 0;
	padding: 0;
}

.chips-demo {
	border: 1px solid var(--grey-50);
	border-radius: var(--border-radius-50);
	width: 300px;
}

.lesson .exercise .answer {
	background: var(--card-background);
	align-items: center;
	border: 1px solid var(--grey);
	border-radius: var(--border-radius-50);
	display: flex;
	gap: var(--padding-50);
	padding: var(--padding-25);
	transition: var(--transition);
	width: 200px;
}

@media only screen and (max-width: 600px) {
	.lesson .exercise .answer {
		width: 140px;
	}
}

.lesson .exercise input[type=text] {
	background: transparent;
	border: none;
	color: var(--body-text-color);
	font-size: var(--font-size-125);
	min-width: 0;
	padding: var(--padding-50);
	outline: none;
	flex: 1;
}

.lesson .exercise input[type=text]:disabled {
	opacity: 1;
}

.lesson .exercise input[type=text]::selection {
	border-radius: 2px;
	background: var(--blue-25-transparent);
}

.lesson .exercise .answer:has(input[type=text]:focus) {	
	border: 1px solid var(--field-focus-color) !important;
	outline: 3px solid var(--field-outline-color);
}

.lesson .exercise .answer.correct {
	border: 1px solid var(--turquoise);
}

.lesson .exercise .answer.error {
	border: 1px solid #ec1801;
	color: #ec1801;
}

.lesson .exercise .answer.accentError {
	border: 1px solid #ffb700;
}

.lesson .exercise .answer.short {
	width: 120px;
}

.lesson .exercise .answer.long {
	width: 280px;
}

@media only screen and (max-width: 600px) {
	.lesson .exercise .answer.long {
		width: 100% !important;
	}
}

.lesson .exercise .answer.extralong {
	max-width: 100%;
	width: 360px;
}

.lesson .exercise .answer-feedback {
	background: var(--card-background);
	box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
	border-radius: var(--border-radius-50);
	display: none;
	font-size: var(--font-size);
	margin-top: 4px;
	position: absolute;
}

.lesson .exercise .answer-feedback.visible {
	display: block;
}

.lesson .exercise .answer-feedback .answer-feedback-wrapper {	
	border-bottom: 1px solid var(--card-divider-border);
	padding: var(--padding-25) var(--padding);
}

.lesson .exercise .answer-feedback .reveal-answer-wrapper {
	height: 30px;
	padding: var(--padding-25) var(--padding);
}


.lesson .exercise .mark {
	align-items: center;
	border: 1px solid var(--card-divider-border);
	border-radius: var(--border-radius-50);
	color: var(--turquoise);
	display: flex;
	flex-direction: row;
	font-weight: bold;
	font-size: var(--font-size-125);
	gap: var(--padding);
	justify-content: space-between;
	margin: var(--padding-200);
	min-width: calc(100% - var(--padding-400));
	padding: var(--padding-150);
}

@media only screen and (max-width: 600px) {
	.lesson .exercise .mark {
		border-radius: 0;
		border: none;
		border-top: 1px solid var(--card-divider-border);
		margin: 0;
		margin-top: var(--padding-150);
		width: 100%;
	}
}

.lesson .exercise .mark.complete {
	background: var(--turquoise-10-transparent);
	border-color: transparent;
	color: var(--turquoise);
}

.lesson .exercise .mark-wrapper {
	align-items: center;
	background: var(--h1-background);
	border-radius: 48px;
	color: var(--h1-text);
	font-size: var(--font-size-150);
	justify-content: center;
	display: flex;
	height: 48px;
	width: 48px;
}

.lesson .exercise .mark.complete .mark-wrapper {
	background: var(--turquoise);
	color: var(--white);
}


/* Audio player */

.audio-player .progress .progress-bar {
	-webkit-appearance: none;
	border-radius: 4px;
	border: none;
	cursor: pointer;
	height: 4px;
	background: rgba(255, 255, 255, 0.20);
	flex-grow: 1;
}

.audio-player .progress .progress-bar::progress {
	background: var(--orange);
	height: 4px;
	width: 100px;
}


/* Exercise - Bucket sort */

.bucket-sort {
	padding: 0 var(--padding-300);
	display: flex;
	flex-direction: column;
	gap: var(--padding-150);
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.bucket-sort {
		padding: 0 var(--padding-150);
	}
}

.bucket-sort .bucket-wrapper {
	display: flex;
	gap: var(--padding-150);
}

@media only screen and (max-width: 600px) {
	.bucket-sort .bucket-wrapper {
		flex-direction: column;
	}
}

.bucket-sort .bucket {
	align-items: center;
	display: flex;
	border: 1px solid var(--card-divider-border);
	border-radius: var(--border-radius);
	flex-direction: column;
	flex: 1;
	gap: var(--padding-150);
	padding: var(--padding-150);
}

.bucket-sort .bucket.selectable {
	animation-timing-function: ease-in-out;
	border-color: var(--link-highlight-color);
	border-width: 2px;
}

@keyframes selection-pulse {
	0% {
		border-color: var(--link-highlight-color);
		border-width: 
	}
	50% {
		border-color: var(--link-color);
	}
	100% {
		border-color: var(--link-highlight-color);
	}
}

.bucket-sort .bucket.selectable:hover {
	border-color: var(--link-color);
	cursor: pointer;
	background: var(--link-highlight-color);
}

.bucket-sort .bucket.incorrect {
	animation: shake 500ms;
}

@keyframes shake {
	0% {
		transform: translateX(-5px) rotate(5deg);
	}
	10% {
		transform: translateX(5px);
	}
	20% {
		transform: translateX(-5px) rotate(-5deg);
	}
	30% {
		transform: translateX(-5px) rotate(5deg);
	}
	40% {
		transform: translateX(5px);
	}
	50% {
		transform: translateX(-5px) rotate(-5deg);
	}
}

.bucket-sort .bucket .bucket-label {
	align-items: center;
	background: var(--card-background);
	display: flex;
	gap: var(--padding);
	justify-content: center;
	margin: -31px 0 0 0;
	padding: 0 var(--padding);
	font-weight: bold;
}

.bucket-sort .bucket .bucket-well {
	box-shadow: 0 0 20px var(--charcoal-10-transparent) inset;
	border-radius: var(--border-radius-50);
	min-height: 300px;
	overflow: hidden;
	padding: var(--padding-150) 0;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.bucket-sort .bucket .bucket-well {
		min-height: 80px;
		max-height: 80px;
	}
}

.bucket-sort .bucket .bucket-well-wrapper {
	align-items: flex-start;
	display: flex;
	gap: var(--padding);
	flex-wrap: wrap;	
	padding: 0 var(--padding-150);
}

@media only screen and (max-width: 600px) {
	.bucket-sort .bucket .bucket-well-wrapper {
		height: 80px;
		flex-wrap: nowrap;
		overflow-x: scroll;
	}
}

.bucket-sort .bucket .bucket-well .chip {
	cursor: default;
}

.bucket-sort .chip {
	background: var(--card-background);
	border-radius: var(--border-radius) !important;
	font-size: var(--font-size-150);
	padding: var(--padding-50) var(--padding) !important;
	transition: 0s !important;
}

.bucket-sort .chip.selected {
	background: var(--h1-background);
	border-color: var(--h1-background);
	color: var(--white);
}

.bucket-sort .chips-well {
	display: flex;
	flex-direction: column;
	gap: var(--padding-200);
	padding: var(--padding-300);
}

@media only screen and (max-width: 600px) {
	.bucket-sort .chips-well {		
		padding: var(--padding);
		gap: var(--padding);
	}
}

.bucket-sort .chips-well .chips-wrapper {
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
	gap: var(--padding-25);
	width: 100%;
}

.bucket-sort .chips-well .chips-wrapper .chip {	
	cursor: pointer;
	position: relative;
}

.bucket-sort .chips-well .chip-clue {	
	background: var(--blue-5-transparent);
	border-radius: var(--border-radius);
	display: flex;
	max-width: 500px;
	padding: var(--padding);
}


/* Lesson summary */

.lesson-summary {
	display: flex;
	gap: var(--padding-200);
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.lesson-summary {
		gap: var(--padding-300);
		flex-direction: column;
	}
}

.lesson-summary .progress-summary {
	display: flex;
	gap: var(--padding-150);
	flex: 1;
	flex-direction: column;
}

@media only screen and (max-width: 600px) {
	.lesson-summary .progress-summary  {
		order: 1;
	}
}

.lesson-summary .card {
	justify-content: flex-start;
	border-radius: var(--border-radius);
	gap: var(--padding-200);
	flex: 0;
	transition: var(--transition);
}

.lesson-summary .card.feedback-reminder {
	gap: var(--padding);
}

.lesson-summary .card.feedback-reminder h2 {
	background: transparent;
	color: var(--body-text-color);
	font-size: var(--font-size);
	margin: 0;
	padding: 0 0 var(--padding-50) 0;
	width: 100%;
}

.lesson-summary .card.feedback-reminder p {
	font-size: var(--font-size);
	line-height: var(--line-height-110);
	margin: 0;
}

.lesson-summary .card.overall-progress {
	border-top: 6px solid var(--link-color);
	justify-content: center;
	min-height: 176px;
}

.lesson-summary .card.lesson-grades {
	border-top: 6px solid var(--turquoise);
}

ul.course-contents {
	border: 1px solid var(--background-colour);
	border-radius: var(--border-radius);	
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
	display: flex;	
	flex: 1;
	flex-direction: column;
	gap: 1px;
	list-style: none;
	margin: 0;
	padding: 0 !important;
	overflow: hidden;
}

@media only screen and (max-width: 600px) {
	ul.course-contents {
		order: 2;
	}
}

ul.course-contents li {
	background: var(--card-background);
	color: var(--body-text-color);
	display: flex;
	gap: var(--padding);
	padding: var(--padding) var(--padding-150);
}

ul.course-contents li:hover {
	background: var(--link-highlight-color); 
	color: var(--link-color);
}

.grade-table {
	width: 100%;
}

.grade-table td {
	border-bottom: 1px solid var(--card-divider-border);
	padding: var(--padding) 0;
}

.grade-table td.grade,
.grade-table th.grade {
	text-align: right;
	max-width: 24px;
}

.grade-table th {
	padding: var(--padding) 0 0 0;
	text-align: right;
}

.grade-table th.average {
	padding-right: var(--padding);
}


/* Paradigm */

.paradigm {
	font-size: var(--font-size-125);
	padding: 0 !important;
	max-width: 700px;
}

.paradigm-wrapper {
	display: flex;
	flex-direction: row;
	margin: 0;
	width: 100%;
	overflow-x: visible;
	overflow-y: scroll;
}

.paradigm-card {
	padding: 0 !important;
}

.paradigm td,
.paradigm th {
	border-bottom: 1px solid var(--background-color);
	padding: var(--padding) var(--padding-150);
	vertical-align: top;
}

.paradigm tr:last-child td {
	border-bottom: none;
}

.paradigm th {
	border-width: 2px;
	text-align: left;
}

.paradigm-table {
	padding: 0 !important;
	min-width: 100%;
	overflow: hidden;
}

.paradigm-table thead,
.paradigm-table tbody {
	min-width: 100%;
}

.paradigm-table tr.divider-row {
	border-bottom: 2px solid var(--background-color);
}

.paradigm-table td[rowspan="2"],
.paradigm-table td[rowspan="3"] {	
	border-bottom-width: 0;
	border-left: 1px solid var(--background-color);
	border-right: 1px solid var(--background-color);
	vertical-align: middle;
}

.paradigm-key-table td {
	border-bottom: 1px solid transparent;
	font-weight: bold;
	text-align: right;
}

.paradigm-key-table th {
	border-width: 2px;
}


/* Special styles */

.m {	
	color: var(--link-color);
}

.f {	
	color: var(--cta-primary-background);
}

.n {
	color: var(--grey);
}

.border-tile {
	border: 4px solid var(--body-text-color);
	border-radius: var(--border-radius);
	display: flex;
	font-size: var(--font-size-150) !important;
	justify-content: center;
	padding: var(--padding) !important;
	max-width: 110px;
}

.block.vocab .border-tile {
	min-width: 100px !important;
}

.border-tile p {
	font-size: var(--font-size-200) !important;
}

.border-tile.m {
	border-color: var(--link-color);
}

.border-tile.f {
	border-color: var(--cta-primary-background);
}

.border-tile.n {
	border-color: var(--grey);
}

.image-frame {
	border-radius: var(--border-radius);
	height: 128px;
	width: 128px;
}

.image-frame.medium {
	height: 72px;
	width: 72px;
}

.case-nom {
	color: var(--link-color);
}

.case-acc {
	color: var(--cta-primary-background);
}

.case-dat {
	color: var(--turquoise);
}

.case-gen {
	color: var(--purple);
}

.small {
	font-size: 75%;
}
:root,
body.light {
	
	/* Typography */
  --font-stack: 'IBM Plex Sans', -apple-system, 'Helvetica Neue', Helvetica, sans-serif;
  --font-size: 16px;  
  --font-size-75: 12px;
	--font-size-125: 20px;
	--font-size-150: 24px;
  --font-size-200: 30px;
  --font-size-225: 36px;
  --font-size-300: 42px;
  --font-size-400: 64px;
  
  --line-height-110: 24px;
  --line-height-125: 28px;
  --line-height-150: 32px;
  --line-height-200: 36px;
  --line-height-300: 48px;
  
  /* Padding */  
  --padding: 12px;
  --padding-25: 4px;
	--padding-50: 6px;
  --padding-125: 16px;
  --padding-150: 18px;
	--padding-200: 24px;
	--padding-300: 36px;
  --padding-400: 48px;
  --padding-500: 60px;
	
	/* Border radius */
	--border-radius: 12px;
  --border-radius-25: 4px;
	--border-radius-50: 6px;
  --border-radius-150: 16px;
  --border-radius-200: 24px;
  --border-radius-300: 36px;
	
	/* Transitions */
	--transition: .25s;
  --transition-slow: .5s;
  
  /* Intrinsic colours */
  --orange: #fe4801;
  --orange-600: #e54101;
  --orange-700: #d83d01;
  --orange-800: #b23201;
  --orange-400: #fe6d34;
  --orange-300: #fe9167;
  --orange-200: #ffb699;
	
  --blue: #0b00d1;
  --blue-600: #0900b2;
  --blue-50: #f4f2ff;
  --blue-100: #e7e6fa;
  --blue-200: #c2bff3;
  --blue-300: #b1aedf;
  --blue-400: #918aff;
  --blue-5-transparent: rgba(9, 1, 208, .05);
  --blue-10-transparent: rgba(9, 1, 208, .1);
  --blue-25-transparent: rgba(9, 1, 208, .25);  
  --blue-60-transparent: rgba(9, 1, 208, .6);  
  --blue-400-25-transparent: rgba(145, 138, 255, .25);
  --blue-400-60-transparent: rgba(145, 138, 255, .6);
  
  --turquoise: #1ca2b2;
  --turquoise-50: #f2feff;
  --turquoise-700: #147984;
  --turquoise-10-transparent: rgba(28, 162, 178, .1);
  
  --purple: #c5008f;
	
	--charcoal: #1c1e21;
  --charcoal-10-transparent: rgba(28, 30, 33, .1);
  --charcoal-20-transparent: rgba(28, 30, 33, .2);
  --charcoal-400: #2b2e33;
  
  --grey: #828b99;
  --grey-50: #d2d2d3;
	
	--white: #ffffff;
  --white-10-transparent: rgba(255, 255, 255, .1);
  --white-30-transparent: rgba(255, 255, 255, .3);
  --white-50-transparent: rgba(255, 255, 255, .5);
  --white-80-transparent: rgba(255, 255, 255, .8);
  --white-95-transparent: rgba(255, 255, 255, .95);
	
	--tan: #f4f1ed;
  --tan-50: #ebe9e5;
  --tan-5-transparent: rgba(244, 241, 237, .5);
  --dark-tan: #333332;
  --dark-tan-05-transparent: rgba(51, 51, 50, .05);
  --dark-tan-5-transparent: rgba(51, 51, 50, .5);
  --dark-tan-8-transparent: rgba(51, 51, 50, .8);
  --dark-tan-95-transparent: rgba(51, 51, 50, .95);
  
  --red: #ec1801;
  --red-10-transparent: rgba(236, 24, 1, .1);
  --red-25-transparent: rgba(236, 24, 1, .25);
  
  /* Semantic colours */
  --background-color: var(--tan);
  --conversation-background-color: var(--blue);
  
  --nav-background: var(--white-95-transparent);
  --nav-external-background: var(--white-80-transparent);
      
  --breadcrumbs-divider: var(--grey);
  
  --body-text-color: var(--charcoal);
  --body-text-color-10-transparent: var(--charcoal-10-transparent);
  
  --link-color: var(--blue);
  --link-highlight-color: var(--blue-10-transparent);
  
  --field-focus-color: var(--blue);
  --field-outline-color: var(--blue-25-transparent);
  
  --cta-primary-background: var(--orange);
  --cta-primary-background-hover: var(--orange-700);
  --cta-primary-text: var(--white);
  --cta-secondary-border: var(--charcoal);
  --cta-secondary-text: var(--charcoal);
  --cta-secondary-background-hover: var(--charcoal);
  --cta-secondary-text-hover: var(--white);
  --cta-disabled-border: var(--grey);
  --cta-disabled-text: var(--grey);
  
  --toggle-button-outline: var(--blue-200);
  --toggle-button-selected: var(--blue-100);
  
  --checkbox-background: var(--charcoal-10-transparent);
  
  --icon-button-fill: var(--blue);
  
  --h1-background: var(--blue);
  --h1-background-transparent: var(--blue-60-transparent);
  --h1-text: var(--white);
  --h2-background: var(--orange);
  --h2-text: var(--white);
  
  --card-background: var(--white);
  --card-divider-border: var(--tan-50);
  --card-background-secondary: var(--white-50-transparent);
  
  --callout-tip-background: var(--turquoise-50);
  --callout-tip-text: var(--turquoise-700);
  --callout-task-background: var(--blue-50);
  --callout-task-text: var(--blue);
      
  --footer-background: var(--dark-tan-05-transparent);
  --footer-text: var(--dark-tan-8-transparent);
  --footer-link-hover-color: var(--charcoal);
      
  --table-row-even-background: var(--tan-5-transparent);
  
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Semantic colours */
    --background-color: var(--charcoal);  
    --conversation-background-color: var(--blue-600);
    
    --nav-background: var(--dark-tan-95-transparent);
    --nav-external-background: var(--dark-tan-8-transparent);
    
    --breadcrumbs-divider: var(--grey-50);
    
    --body-text-color: var(--grey-50);
    --body-text-color-10-transparent: var(--white-10-transparent);
    
    --link-color: var(--blue-300);
    --link-highlight-color: var(--blue-10-transparent);
      
    --field-focus-color: var(--blue-300);
    --field-outline-color: var(--blue-400-25-transparent);
    
    --cta-primary-background: var(--orange);
    --cta-primary-background-hover: var(--orange-700);
    --cta-primary-text: var(--white);
    --cta-secondary-border: var(--grey-50);
    --cta-secondary-text: var(--grey-50);
    --cta-secondary-background-hover: var(--grey-50);
    --cta-secondary-text-hover: var(--charcoal);
    --cta-disabled-border: var(--grey);
    --cta-disabled-text: var(--grey);
      
    --checkbox-background: var(--white-30-transparent);
      
    --toggle-button-outline: var(--blue-200);
    --toggle-button-selected: var(--blue-100);
      
    --icon-button-fill: var(--blue-300);
    
    --h1-background: var(--blue-400);
    --h1-background-transparent: var(--blue-400-60-transparent);
    --h1-text: var(--white);
    --h2-background: var(--orange);
    --h2-text: var(--white);
    
    --card-background: var(--dark-tan);
    --card-divider-border: var(--charcoal);
    --card-background-secondary: var(--dark-tan-5-transparent);
      
    --callout-tip-background: var(--turquoise-700);
    --callout-tip-text: var(--turquoise-50);
    --callout-task-background: var(--blue-25-transparent);
    --callout-task-text: var(--blue-50);
    
    --footer-background: var(--dark-tan-95-transparent);
    --footer-text: var(--grey-50);
    --footer-link-hover-color: var(--white);
    
    --table-row-even-background: var(--charcoal-20-transparent);
  }
}

body.dark {
  /* Semantic colours */
  --background-color: var(--charcoal);  
  --conversation-background-color: var(--blue-600);
  
  --nav-background: var(--dark-tan-95-transparent);
  --nav-external-background: var(--dark-tan-8-transparent);
  
  --breadcrumbs-divider: var(--grey-50);
  
  --body-text-color: var(--grey-50);
  --body-text-color-10-transparent: var(--white-10-transparent);
  
  --link-color: var(--blue-300);
  --link-highlight-color: var(--blue-10-transparent);
        
  --field-focus-color: var(--blue-300);
  --field-outline-color: var(--blue-400-25-transparent);
  
  --cta-primary-background: var(--orange);
  --cta-primary-background-hover: var(--orange-700);
  --cta-primary-text: var(--white);
  --cta-secondary-border: var(--grey-50);
  --cta-secondary-text: var(--grey-50);
  --cta-secondary-background-hover: var(--grey-50);
  --cta-secondary-text-hover: var(--charcoal);
  --cta-disabled-border: var(--grey);
  --cta-disabled-text: var(--grey);
    
  --icon-button-fill: var(--blue-300);
  
  --h1-background: var(--blue-400);
  --h1-background-transparent: var(--blue-400-60-transparent);
  --h1-text: var(--white);
  --h2-background: var(--orange);
  --h2-text: var(--white);
  
  --card-background: var(--dark-tan);
  --card-divider-border: var(--charcoal);
  --card-background-secondary: var(--dark-tan-5-transparent);
        
  --callout-tip-background: var(--turquoise-700);
  --callout-tip-text: var(--turquoise-50);
  --callout-task-background: var(--blue-25-transparent);
  --callout-task-text: var(--blue-50);
      
  --footer-background: var(--dark-tan-95-transparent);
  --footer-text: var(--grey-50);
  --footer-link-hover-color: var(--white);
  
  --table-row-even-background: var(--charcoal-20-transparent);
}
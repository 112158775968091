/* Container */

@media only screen and (max-width: 600px) {
	section .container {		
		padding: var(--padding-200) var(--padding-150) !important;
		margin: 0 !important;
	}
}

/* Homepage banner */

header.homepage-banner {
	background-color: #ccc;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center bottom;
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.40);
	min-height: 580px;
}

@media only screen and (max-width: 600px) {
	header.homepage-banner {		
		background-position: left top;	
		background-size: auto 400px;
	}
}

header.homepage-banner .container {
	align-items: flex-end;
	flex-direction: row;
	justify-content: space-between;
	padding: var(--padding-300) 0;
}

@media only screen and (max-width: 600px) {
	header.homepage-banner .container {			
		flex-direction: column;
		padding: 0;
	}
}

header.homepage-banner .blurb {	
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	max-width: 40%;
}

@media only screen and (max-width: 600px) {
	header.homepage-banner .blurb {			
		justify-content: flex-end;
		max-width: 100%;
		height: 300px;
		padding: 0 var(--padding-150);
	}
}

header.homepage-banner .blurb h1 {
	background: var(--charcoal);
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	display: inline;
}

@media only screen and (max-width: 600px) {
	header.homepage-banner .blurb h1 {
		font-size: var(--font-size-200);
		line-height: var(--line-height-200);
	}
}

header.homepage-banner .blurb p {
	margin: var(--padding-200) 0 0 0;
}

header.homepage-banner .blurb p {
	margin: 0;
}

.card.signup-form {
	max-width: 340px;
	min-height: 200px;
	transition: var(--transition);
}

.card.signup-form h2.no-padding {
	margin: 0;
}

@media only screen and (max-width: 600px) {
	.card.signup-form {			
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		max-width: 100%;
	}
}

.signup-form form {
	flex: 1;
	margin: var(--padding-200) 0 0 0;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.signup-form form	{
		margin: var(--padding-150) 0 0 0;
	}
}

.signup-success {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	gap: var(--padding-150);
	line-height: var(--line-height-110);
	padding: var(--padding);
	text-align: center;
}


/* Homepage courses */

@media only screen and (max-width: 600px) {
	section.homepage-courses .blurb {			
		padding: 0 var(--padding-150);
	}
}

.avatar-wrapper-home {
	align-items: center;
	display: flex;
	gap: var(--padding-125);
	flex-direction: row;
}

.avatar-wrapper-home p {
	font-size: var(--font-size) !important;
	line-height: var(--font-size-125) !important;
}

.avatar-wrapper-home img.mugshot {
	border-radius: 64px;
	height: 64px;
	width: 64px;
}

.course-info-wrapper {
	display: flex;
	flex-direction: row;
	gap: var(--padding-200);
	justify-content: space-between;
}

@media only screen and (max-width: 600px) {
	.course-info-wrapper {			
		flex-direction: column;
		gap: var(--padding-150);
		padding: 0;
	}
}

.course-cards-carousel {
	display: flex;
	overflow-x: scroll;
	padding: var(--padding-25) 0;
	scroll-snap-type: x mandatory;
	max-width: 100%;
}

.course-cards-carousel::-webkit-scrollbar {
	display: none;
}

.course-cards-wrapper {
	display: flex;
	flex-direction: row;
	gap: var(--padding-200);
}

@media only screen and (max-width: 600px) {
	.course-cards-wrapper {		
		gap: var(--padding-150);	
		padding: 0 var(--padding-150);
	}
}

.card.store-course-card {
	border-bottom-left-radius: var(--border-radius-300);
	border-bottom-right-radius: var(--border-radius-300);
	justify-content: space-between;
	border-top: 6px solid var(--icon-button-fill);
	padding: var(--padding-150);
	scroll-snap-align: center;
  width: 340px;
}

@media only screen and (max-width: 600px) {
	.card.store-course-card {			
		max-width: 85vw;
	}
}

.card.store-course-card.skeleton {
	height: 588px;
}

@keyframes pulse {
	0% {
		background-color: rgba(0,0,0,0.25);
	}
	50% {
		background-color: rgba(0,0,0,0.1);
	}
	100% {
		background-color: rgba(0,0,0,0.25);
	}
}

.card.store-course-card .course-info {
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	width: 100%;
}

.course-name {
	align-items: center;
	display: flex;
	gap: var(--padding);
	justify-content: space-between;
	width: 100%;
}

.card.store-course-card .course-info .course-purchased-badge {
	padding: var(--padding) 0;
}

.card.store-course-card ul,
.product-details ul,
ul.ticklist {
	display: flex;
	flex-direction: column;
	gap: var(--padding-50);
	list-style: none;
	margin: 0;
	padding: var(--padding) 0;
	min-width: 100%;
}

.card.store-course-card ul li,
.product-details ul li,
ul.ticklist li {
	align-items: flex-start;
	display: flex;
	gap: var(--padding-50);
}

.card.store-course-card ul li .icon,
.product-details ul li .icon,
ul.ticklist li .icon {
	max-height: 20px;
	max-width: 20px;
	min-height: 20px;
	min-width: 20px;
}

.card.store-course-card .icon path,
.product-details .icon path,
ul.ticklist path {	
	fill: var(--body-text-color) !important;
}

.card.store-course-card .course-price-cta {
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	width: 100%;
}

.card.store-course-card .course-pricing {
	align-items: center;
	border-top: 1px solid rgba(130, 139, 153, .25);
	display: flex;
	flex-direction: row;
	font-size: var(--font-size-75);
	font-weight: bold;
	gap: var(--padding-200);
	justify-content: space-between;
	min-height: 80px;
	padding:0;
	width: 100%;
}

.card.store-course-card .course-pricing .promo-text {
	color: var(--red);
	display: flex;
	gap: var(--padding-25);
	font-size: var(--font-size-75);
	font-weight: bold;
}

.card.store-course-card .course-pricing .promo-text .icon path {
	fill: var(--red) !important;
}

.card.store-course-card .course-pricing .price-detail {
	align-items: center;
	display: flex;
	gap: var(--padding-25);
	padding: var(--padding) 0;
}

.price {
	font-weight: bold;
	font-size: var(--font-size-300);
}

.card.store-course-card button,
.card.store-course-card .button {
	width: 100%;
}

.payment-options {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: var(--padding-200);
	margin: var(--padding-200) 0 0 var(--padding);
}

@media only screen and (max-width: 600px) {
	.payment-options {			
		gap: var(--padding-150);
		margin: 0 var(--padding-200);
	}
}


/* Homepage USPs */

section.homepage-usps {
	background: rgba(253, 72, 2, .1);
	border-top: 6px solid var(--orange);
}

section.homepage-usps h2 {
	font-size: var(--font-size-225);
	margin: 0;
}

section.homepage-usps .prose {
	max-width: fit-content;
}

@media only screen and (max-width: 600px) {
	section.homepage-usps .prose {		
		max-width: unset;	
		width: 100%;
	}
	
	section.homepage-usps .prose .button {
		width: 100%;
	}
}


/* Homepage about me */

section.homepage-about-me {
	margin-top: var(--padding);
}

.container.about-me {
	gap: 0;
}

.container.about-me.padded {
	gap: var(--padding-150);
}

@media only screen and (max-width: 600px) {
	.homepage-about-me .hero-image {
		object-position: -200px center;
	}
}

.about-me-wrapper {
	display: flex;
	flex-direction: row;
	gap: var(--padding-500);
}

@media only screen and (max-width: 600px) {
	.about-me-wrapper {			
		flex-direction: column;
		gap: var(--padding-200);
	}
}

.about-me-timeline {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: var(--padding-150) 0;
	gap: var(--padding-125);
	max-width: 320px;
}

@media only screen and (max-width: 600px) {
	.about-me-timeline {			
		padding: 0;
		max-width: 100%;
	}
}

.about-me-timeline li {
	padding-left: 22px;
	position: relative;
}

.about-me-timeline li::before {
	border: 4px solid var(--icon-button-fill);
	border-radius: 24px;
	content: '';
	display: block;
	left: 2px;
	height: 4px;
	position: absolute;
	top: 4px;
	width: 4px;
}


/* Homepage more Icelandic */

section.homepage-more-icelandic {
	background: rgba(0,0,0,.1);
}

section.homepage-more-icelandic h2 {
	font-size: var(--font-size-225);
	margin: 0;
}


/* Homepage mailing list */

section.homepage-hero-cta {
	margin-top: var(--padding);
}

section.homepage-hero-cta .container {
	margin-top: -72px;
}

.container.hero-cta {
	background: var(--blue-600);
	border-radius: var(--border-radius-200);
	box-shadow: 0 1px 2px 0 rgba(0,0,0,.25);
	color: var(--white);
	padding: var(--padding-300);
	max-width: 1072px;
}

@media only screen and (max-width: 600px) {
	.container.hero-cta {			
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		padding: var(--padding-200);
		margin-top: -200px !important;
	}
}

.container.hero-cta h2 {
	background: transparent;
	padding: 0;
}

@media only screen and (max-width: 600px) {
	.container.hero-cta button {			
		width: 100%;
	}
}

form#mailing-list {
	align-items: center;
	flex-direction: row;
	justify-content: stretch;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	form#mailing-list {		
		flex-direction: column;
	}
}

form#mailing-list input {
	flex: 1;
	border-color: transparent;
	border-radius: var(--border-radius);
	min-height: 46px;
}

@media only screen and (max-width: 600px) {
	form#mailing-list input {
		width: 100%;
	}
}

form#mailing-list input:focus {
	border: 1px solid var(--orange-600);
	outline: 3px solid var(--orange);
	outline-offset: 0;
}


/* Blog */

.blog-posts {
	display: flex;
	flex-wrap: wrap;
	gap: var(--padding-200);
}

@media only screen and (max-width: 600px) {
	.blog-posts {			
		flex-direction: column;
		flex-wrap: nowrap;
		gap: var(--padding-150);
	}
}

.blog-post-card {
	gap: 0 !important;
	min-height: 313px;
	min-width: calc(33.33% - var(--padding-200));
	max-width: calc(33.33% - var(--padding-200));
	overflow: hidden;
	padding: 0 !important;
}

@media only screen and (max-width: 600px) {
	.blog-post-card {			
		min-width: 100%;
	}
}

.blog-post-card:hover {
	box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

.blog-post-card img {
	width: 100%;
	transition: var(--transition);
}

.blog-post-card:hover img {
	filter: brightness(105%);
}

.blog-post-card-title {
	color: var(--body-text-color);
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	justify-content: space-between;
	min-height: 140px !important;
	padding: var(--padding-150);
}

.blog-post-card-title h2 {
	margin: 0 !important;
	padding: 0 !important;
}

.blog-post-card-title p {
	margin: 0 !important;
	padding: 0 !important;
}


/* Blog post */ 

.container.blog {
	align-items: center;
}

.blog-container {
	margin: 0 auto;
	width: 800px;
}

@media only screen and (max-width: 600px) {
	.blog-container {			
		width: 100%;
	}
}

.blog-intro {
	display: flex;
	flex-direction: column;
	gap: var(--padding-150);
	padding-bottom: var(--padding);
}

.blog-intro .blog-link {
	background: var(--h2-background);
	color: var(--h2-text); 
	display: inline-block;
	font-size: var(--font-size-125);
	font-weight: bold;
	margin-bottom: var(--padding);
	padding: 0 var(--padding-50);
}

.blog-intro p {
	line-height: var(--line-height-125);
	margin: var(--padding) 0 var(--padding-50) 0;
}

.standfirst {
	font-size: var(--font-size-125);
}

.standfirst::before {
	background: var(--h2-background);
	border-radius: var(--border-radius-200);
	content: '';
	display: inline-block;
	margin-right: var(--padding-50);
	height: 14px;
	width: 14px;
}

.standfirst.skeleton::before {
	display: none;
}

.blog-banner {
	border-radius: var(--border-radius-150);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
	min-height: 600px;
	overflow: hidden;
	width: 1100px;
}

@media only screen and (max-width: 600px) {
	.blog-banner {		
		min-height: unset;
		width: 100%;
	}
}

.blog-banner-image {
	width: 100%;
}

.blog-post {
	display: flex;
	flex-direction: column;
	gap: var(--padding-200);
}

.blog-post .blog-title {
	margin-left: calc(300px + var(--padding-150));
}

@media only screen and (max-width: 600px) {
	.blog-post .blog-title {
		margin-left: 0;
	}
}

.blog-author {
	align-items: center;
	display: flex;
	gap: var(--padding-150);
	min-width: 300px;
}

@media only screen and (max-width: 600px) {
	.blog-author {			
		width: 100%;
	}
}

.blog-author .avatar {
	align-items: flex-start;
}

.blog-author .blog-author-details {
	display: flex;
	flex-direction: column;
	gap: var(--padding-50);
}

.blog-author .mugshot {
	border-radius: 100px;
	height: 48px;
	width: 48px;
}

.blog-body {
	display: flex;
	flex-direction: column;
	padding-bottom: var(--padding-400);
}

.blog-body p {
	font-size: var(--font-size-125);
	line-height: var(--line-height-150);
	margin: 0;
	padding: 0;
}

.blog-body h2,
.blog-body h3,
.blog-body h4 {
	background: transparent !important;
	color: var(--body-text-color) !important;
	padding: 0 !important;
}

.blog-body li {
	font-size: var(--font-size-125);
	line-height: var(--line-height-150);
}

.blog-body .block-image-wrapper {
	border-radius: var(--border-radius);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
	margin: var(--padding) 0;
	overflow: hidden;
	width: 100%;
}

.blog-body .block-image-wrapper img {
	width: 100%;
}


/* Store */

.container.store {
	margin: 0 auto !important;
	padding: 0 !important;
	padding-bottom: var(--padding-125) !important;
}

.store-section .prose {
	max-width: 80ch;
}

@media only screen and (max-width: 1000px) {
	.store-section .prose {
		padding: 0 var(--padding-150);
	}
}

.store-section .prose h2 {
	margin: 0;
}

.store-section .prose p {
	font-size: var(--font-size-125);
  line-height: var(--line-height-125);
	padding: 0;
}

.store-section ul.ticklist {
	color: var(--turquoise-700);
	flex-direction: row;
	gap: var(--padding-50) var(--padding-200);
	margin: 0;
	padding: 0;
	padding-bottom: var(--padding-200);
}

@media only screen and (max-width: 600px) {
	.store-section ul.ticklist {
		flex-direction: column;	
	}
}

.store-section ul.ticklist li .icon path {
	fill: var(--turquoise) !important;
}


/* Product page */

.product-page {
	gap: var(--padding-200);
}

@media only screen and (max-width: 600px) {
	.product-page {
		gap: var(--padding-150) !important;
	}
}

.product-intro {
	display: flex;
	flex-direction: column;
	gap: var(--padding-200);
	max-width: 700px;
}

@media only screen and (max-width: 600px) {
	.product-intro {
		gap: var(--padding-150);
	}
}

.product-intro-title {
	display: flex;
	flex-direction: column;
	gap: var(--padding-150);
}

.product-intro h1 {
	text-wrap: balance !important;
}

.product-intro p {
 	font-size: var(--font-size-125);
	line-height: var(--line-height-125);
	margin: 0;
	padding: 0;
}

.product-byline a:hover {
	background: var(--link-highlight-color);
	color: var(--link-color);
}

.product-byline {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: var(--padding-25) var(--padding-200);
	margin: -4px;
}

.product-vendor {
	align-items: center;
	border-radius: var(--border-radius-50);
	color: var(--body-text-color);
	display: flex;
	font-weight: bold;
	gap: var(--padding-50);
	padding: var(--padding-25);
}

.product-level {
	align-items: center;
	border-radius: var(--border-radius-50);
	color: var(--body-text-color);
	display: flex;
	font-weight: bold;
	gap: var(--padding-50);
	padding: 2px 2px 2px var(--padding-25);
}

.product-level span,
.product-level-badge {
	border: 1px solid var(--body-text-color);
	border-radius: var(--border-radius-25);
	font-size: var(--font-size);
	line-height: var(--font-size);
	padding: 3px 5px;
}

.product-level:hover span {
	border-color: var(--link-color);
}

.product-human-made {
	border: 1px solid var(--h2-background);
	border-radius: var(--border-radius-25);
	color: var(--h2-background);
	display: flex;
	font-weight: bold;
	margin: 4px;
	gap: var(--padding-50);
	padding: 2px var(--padding-50);
}

.product-human-made:hover {
	background: var(--h2-background) !important;
	color: var(--h2-text) !important;
}

.product-human-made:hover path {
	fill: var(--h2-text);
}

.avatar.large {
	border-radius: var(--border-radius-50);
	flex: 0;
	min-height: 72px;
	min-width: 72px;
}

.avatar img {
	max-width: 100%;
}

.product-vendor-avatar {
	border-radius: var(--border-radius-25);
	height: 24px;
	width: 24px;
}

.product-carousel {
	gap: 0 !important;
	min-height: 400px;
	padding: 0 !important;
}

@media only screen and (max-width: 600px) {
	.product-carousel {
		min-height: unset;
	}
}

.product-carousel.skeleton {
	min-height: 200px;
}

.product-image-carousel {
	padding-top: var(--padding-200);	
	max-width: 100%;
	position: relative;
}

@media only screen and (max-width: 600px) {
	.product-image-carousel {
		padding-top: var(--padding-150);
	}
}

button.arrow-left,
button.arrow-right {
	background: var(--body-text-color);
	display: none;
	left: -20px;
	height: 40px;
	width: 40px;
	padding: 0;
	top: 159px;
	position: absolute;
	z-index: 500;
}

button.arrow-left:hover,
button.arrow-right:hover {
	background: var(--charcoal-400);
}

button.arrow-left path,
button.arrow-right path {
	fill: var(--white) !important;
}

button.arrow-right {
	left: unset;
	right: -20px;
}

button.arrow-left.visible,
button.arrow-right.visible {
	display: block;
}

@media only screen and (max-width: 600px) {
	button.arrow-left.visible,
	button.arrow-right.visible {
		display: none;
	}
}

.product-image-carousel-wrapper {	
	width: 100%;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
}

.product-image-carousel-wrapper::-webkit-scrollbar {
	display: none;
}

.product-image-carousel-image-wrapper {
	display: flex;
	gap: var(--padding-150);
	padding: 0 var(--padding-200);
	width: min-content;
}

@media only screen and (max-width: 600px) {
	.product-image-carousel-image-wrapper {
		padding: 0 var(--padding-150);
		
	}
}

.product-image-carousel-image-wrapper img {
	background: var(--charcoal-10-transparent);
	border-radius: var(--border-radius-50);
	object-fit: cover;
	outline: 1px solid rgba(0,0,0,0.2);
	outline-offset: -1px;
	height: 310px;
	width: 440px;
}

@media only screen and (max-width: 600px) {
	.product-image-carousel-image-wrapper img {
		height: auto;
		scroll-snap-align: center;
		width: 75vw;
	}
}

.product-cta-wrapper {
	align-items: center;
	display: flex;
	gap: var(--padding);
	justify-content: space-between;
	padding: var(--padding-200);
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.product-cta-wrapper {
		align-items: flex-start;
		flex-direction: column;
		padding: var(--padding-150);
	}
	
	.product-cta-wrapper a {
		width: 100%;
	}
	
	.product-cta-wrapper a button {
		width: 100%;
	}
}

.product-price-wrapper {
	align-items: center;
	display: flex;
	gap: var(--padding);
}

@media only screen and (max-width: 600px) {
	.product-price-wrapper {
		justify-content: space-between;
		width: 100%;
	}
}

.product-promo-text {
	align-items: center;
	color: var(--red);
	display: flex;
	gap: var(--padding-25);
	font-weight: bold;
}

.product-promo-text .icon path {
	fill: var(--red) !important;
}

.product-details-wrapper {
	display: flex;
	gap: var(--padding-200);
}

@media only screen and (max-width: 600px) {
	.product-details-wrapper {
		flex-direction: column-reverse;
	}
}

.product-details-left {
	display: flex;
	flex-direction: column;
	gap: var(--padding-150);
	max-width: 660px;
}

.product-details {
	flex-direction: row !important;
	gap: var(--padding-200) !important;
	max-height: fit-content;
	min-height: fit-content;
	max-width: 700px;
}

@media only screen and (max-width: 600px) {
	.product-details {
		flex-direction: column !important;
		gap: var(--padding-150) !important;
	}
}

.product-details.skeleton {
	min-height: 200px;
}

.product-details .column {
	flex: 1;
}

.product-details .feature-list {
	gap: var(--padding);
}

.inclusions {
	border-top: 4px solid var(--turquoise);
	color: var(--turquoise-700);
	max-height: fit-content;
	padding: var(--padding-150) !important;
	position: sticky;
}

@media only screen and (max-width: 600px) {
	.inclusions {
		max-width: unset;
	}
}

.inclusions h2 {
	margin: 0 !important;
	padding: 0;
}

.inclusions ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.inclusions ul li {
	padding: var(--padding-25) 0;
}

.inclusions ul li path {
	fill: var(--turquoise) !important;
}

.reviews {
	gap: var(--padding-150) !important;
}

.reviews h2 {
	margin: 0 !important;
}

.review {
	display: flex;
	flex-direction: column;
}

.review-info {
	align-items: baseline;
	display: flex;
	gap: var(--padding);
}

.review-rating {
	display: flex;
	gap: 1px;
}

.review-date {
	font-size: var(--font-size-75);
	font-weight: bold;
}

.vendor-intro {
	flex-direction: row !important;
}

dialog .tips {
	max-width: 100% !important;
	padding: var(--padding) var(--padding-400) !important;
}

@media only screen and (max-width: 600px) {
	dialog .tips {
		padding: var(--padding) 0 !important;
	}
	
	dialog .tips li {
		align-items: flex-start !important;
	}
}


/* External page */

.faq-block {
	padding: var(--padding-200) 0;
}

.faq {
	border-top: 1px solid var(--grey-50);
	padding: var(--padding) 0;
}

.faq .faq-question {
	align-items: baseline;
	cursor: pointer;
	display: flex;
	font-weight: bold;
	font-size: var(--font-size-125);
	gap: var(--padding-50);
}

.faq .faq-answer {
	max-height: 0;
	max-width: 80ch;
	opacity: 0;
	transform: scale(1, 0);
	transform-origin: top;
	transition: var(--transition);
}

.faq.expanded .faq-answer {
	max-height: 2000px;
	transform: scale(1, 1);
	opacity: 1;
	height: auto;
}

.faq-icon {
	transition: var(--transition);
}

.faq.expanded .faq-icon {
	transform: rotate(-135deg);
}


/* Signup & login form */

.signup-login {
	align-items: center;
	display: flex;
	flex: 1;
	gap: var(--padding-150);
	justify-content: space-between;
	padding: var(--padding-200);
	min-height: fit-content;
	height: 100vh;
}

@media only screen and (max-width: 600px) {
	.signup-login {
		flex-direction: column-reverse;
		padding: var(--padding-150);
	}
}

.signup-login-banner-wrapper {
	align-items: center;
	display: flex;
	justify-content: center;
	opacity: 1;
	transform: translateY(0);
	transition: var(--transition);
	width: 50%;
}

.signup-login-banner-wrapper.hidden {
	opacity: 0;
	transform: translateY(20px);
}

@media only screen and (max-width: 600px) {
	.signup-login-banner-wrapper {
		width: 100%;
	}
}

.signup-login-banner {
	background: var(--card-background-secondary);
	border-radius: var(--border-radius-150);
	gap: 0 !important;
	min-height: 240px;
	max-width: 330px;
	overflow: hidden;
	padding: 0 !important;
}

@media only screen and (max-width: 600px) {
	.signup-login-banner {
		align-items: center !important;
		flex-direction: row !important;
		max-width: unset;
		min-height: unset;
		width: 100%;
	}
}

.signup-login-banner-image-carousel {
	align-items: center;
	display: flex;
}

@media only screen and (max-width: 600px) {
	.signup-login-banner-image-carousel {
		align-items: center;
		max-width: 40%;
		padding: var(--padding);
	}
}

.signup-login-banner-image-carousel img {
	max-width: 100%;
}

@media only screen and (max-width: 600px) {
	.signup-login-banner-image-carousel img {
		border-radius: var(--border-radius-25);
	}
}

.signup-login-banner-body {
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	padding: var(--padding-150);
}

@media only screen and (max-width: 600px) {
	.signup-login-banner-body {		
		padding: var(--padding-150) var(--padding-50);
	}
}

.signup-login-banner-body h2 {
	margin: 0 !important;
}

.signup-login-banner-body p {	
	margin: 0 !important;
}

.signup-login-banner .terms {
	font-size: var(--font-size-75);
}

.signup-login-form-card {
	align-items: center !important;
	border-radius: var(--border-radius-200);
	border-top: 4px solid transparent;
	background: var(--card-background);
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	padding: var(--padding-300) !important;
	min-height: fit-content;
	min-width: fit-content;
	height: 100%;
	width: 50%;
	max-width: 800px;
}

@media only screen and (max-width: 600px) {
	.signup-login-form-card {
		height: auto;
		width: 100%;
		padding: var(--padding-150) !important;
	}
}

.signup-login-form-card .blurb {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: var(--padding);
}

.signup-login-form-wrapper {
	align-content: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	gap: var(--padding-200);
	max-width: 540px;
}

@media only screen and (max-width: 600px) {
	.signup-login-form-wrapper {
		width: 100%;
	}
}

.signup-login-form {
	max-width: 100%;
}

@media only screen and (max-width: 600px) {
	.signup-login-form {
		width: 100%;
	}
}

.signup-login-form .rows {
	gap: var(--padding-200);
}

.signup-login-form .row {
	padding: 0;
	width: 100%;
}

.signup-login-form .cell {
	display: flex;
	flex-direction: column;
	gap: var(--padding-50);
	max-width: calc(50% - var(--padding-50));
}

@media only screen and (max-width: 600px) {
	.signup-login-form .cell {
		max-width: unset;
		width: 100%;
	}
}

.signup-login-form .cell input {
	max-width: 100%;
}

.signup-login-form .row.step-2 {
	display: none;
}

.signup-login-form .row label {
	padding: 0;
}

.signup-login-form button {
	width: 100%;
}

.form-terms {
	font-size: var(--font-size-75);
	text-align: center;
	width: 100%;
}
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('atoms.css');

@import url('external.css');
@import url('lessonPage.css');
@import url('enterprise.css');


/* Basic element resets */

* {
	box-sizing: border-box;
	font-family: var(--font-stack);
	scroll-behavior: smooth;
}

html {
	height: 100%;
}

body {
	background-color: var(--background-color);
	color: var(--body-text-color);
	font-size: var(--font-size);
	height: 100%;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.no-scroll {
	overflow: hidden;
}

body.conversation {
	background-color: var(--conversation-background-color);
}

a {
	color: var(--link-color);
	cursor: pointer;
	text-decoration: none;
	transition: var(--transition);
}

p a:hover,
.breadcrumbs a:hover,
a.highlight:hover {
	background: var(--link-highlight-color);
}

p {
	white-space: pre-wrap;
}

p.intro {
	font-size: var(--font-size-125);
}

@media only screen and (max-width: 600px) {
	.mobile-hidden {		
		display: none !important;
	}
}

.mobile-only {		
	display: none !important;
}

@media only screen and (max-width: 600px) {
	.mobile-only {		
		display: inherit !important;
	}
}

u {
	text-decoration: none;
	border-bottom: 3px double var(--body-text-color);
}


/* Containers */

#root {
	box-sizing: border-box;
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

.body {
	flex-grow: 1;
	min-height: 700px;
}

header {
	display: flex;
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}

section {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	gap: var(--padding-200);
	margin: 60px auto 0 auto;
	padding: var(--padding-200) 0;
	width: 1000px;
}

.container.external {
	margin: 80px auto 0 auto;
}

@media only screen and (max-width: 600px) {
	.container {		
		margin: 56px auto 0 auto !important;
	}
}

@media only screen and (max-width: 1050px) {
	.container {		
		padding: var(--padding-200) var(--padding-150);
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.container.no-x-padding {		
		padding: var(--padding-200) 0 !important;
	}
}

.container.error {
	font-size: var(--font-size-125);
	font-weight: bold;
	min-height: 700px;
}

.container.account {
	max-width: 600px;
}

section .container {
	margin: var(--padding-200) auto;
}

@media only screen and (max-width: 600px) {
	section .container {		
		padding: var(--padding-50) var(--padding-150);
	}
}

.error-wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: var(--padding-200);
	padding: var(--padding-300) 0;
}

.error-wrapper p {	
	max-width: 60ch !important;
	text-align: center;
}

.loading {
	align-items: center;
	display: flex;
	justify-content: center;
	padding: var(--padding-300);
	width: 100%;
}

.placard {
	align-items: center;
	background: var(--dark-tan-05-transparent);
	border-radius: var(--border-radius-50);
	display: flex;
	gap: var(--padding);
	padding: var(--padding);
	width: 100%;
}

.placard.error {
	background: var(--red-10-transparent);
	color: var(--red);
}

.placard.error::before {
	content: url('/static/images/icon-error.svg');
	padding-top: 4px;
}

.placard.success {
	background: var(--turquoise-10-transparent);
	color: var(--turquoise);
}

.placard.success::before {
	content: url('/static/images/icon-success.svg');
	padding-top: 4px;
}

.placard.info {
	background: var(--link-highlight-color);
	color: var(--link-color);
}

.placard.info::before {
	content: url('/static/images/icon-info.svg');
	padding-top: 4px;
}

.placard .content-wrapper {
	align-items: center;
	display: flex;
	gap: var(--padding);
	justify-content: space-between;
	width: 100%;
}

.hero-image {
	height: 400px;
	object-fit: cover;
	width: 100%;
}

.card.trial-expired {
	border-top: 4px solid var(--h2-background);
	flex: unset;
	width: 100% !important;
}

.card.trial-expired h2 {
	color: var(--h2-background);
	margin: 0;
}

.card.trial-expired p {
	margin: 0;
}


/* Footer */

footer {
	background: var(--footer-background);
	color: var(--footer-text);
	flex-grow: 0;
	flex-shrink: 0;
}

.conversation footer {
	color: var(--white);
}

footer .container {
	gap: var(--padding);
	padding: var(--padding-300) 0;
	margin: 0 auto !important;
	width: 1000px;
}

@media only screen and (max-width: 1050px) {
	footer .container {				
		padding: var(--padding-150) var(--padding-150) var(--padding-300) var(--padding-150);		
		width: 100%;
	}
}

footer p {
	margin: 0;
}

footer ul {
	align-content: baseline;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	font-weight: bold;
	gap: var(--padding-50);
	list-style: none;
	margin: 0;
	padding: 0;
}

@media only screen and (min-width: 600px) {
	footer ul {		
		flex-direction: row;
		gap: var(--padding-200);
	}
}

footer a {
	color: var(--footer-text);
}

.conversation footer a {
	color: rgba(255,255,255,.8);
}

.conversation footer a .icon path {
	fill: rgba(255,255,255,.8) !important;
}

footer a:hover {
	background: transparent;
	color: var(--footer-link-hover-color);
}

.conversation footer a:hover {
	color: var(--white);
}

.conversation footer a:hover .icon path {
	fill: var(--white) !important;
}

footer a.footer-icon-link {
	display: flex;
	align-content: baseline;
	align-items: center;
	gap: var(--padding-50);
}

footer a.footer-icon-link .icon {
	height: 16px;
	width: 16px;
}

footer a:hover.footer-icon-link .icon path {
	fill: var(--footer-link-hover-color);
}

footer a.footer-icon-link .icon path {
	fill: var(--footer-text);
}


/* Columns */

.col-2 {
	width: calc((1000px / 2) - (var(--padding-150))) !important;
}

.col-3 {
	width: calc((1000px / 3) - (var(--padding-150))) !important;
}

.col-4 {
	width: calc((1000px / 4) - (var(--padding-150))) !important;
}


/* Top nav */

nav.top {
	align-items: center;
	backdrop-filter: blur(20px);
	background: var(--nav-external-background);
	box-shadow: rgba(0, 0, 0, .1) 0px 1px 2px 0px;
	display: flex;
	gap: var(--padding);
	height: 60px;
	justify-content: space-between;
	padding: var(--padding);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10000;
}

nav.top.external {
	background: var(--nav-external-background);
	height: 80px;
	padding: var(--padding) var(--padding-150);
}

nav.top.external.pre-launch {
	justify-content: center;
}

@media only screen and (max-width: 600px) {
	nav.top.external {			
		height: 60px;
		padding: var(--padding);
	}
}

.logo {	
	align-items: center;
	cursor: default;
	display: flex;
	gap: var(--padding);
	font-size: var(--font-size-150);
	font-weight: bold;
}

.logo a {
	align-items: center;
	display: flex;
	gap: var(--padding);
	color: var(--body-text-color);
	transition: var(--transition);
}

@media only screen and (max-width: 600px) {
	.logo a  {			
		font-size: var(--font-size);
	}
}

.logo a:hover {
	color: var(--link-color);
}

.logo a:hover img {
	filter: brightness(1.1);
}

nav.top .left,
nav.top .right {
	align-items: center;
	display: flex;
	gap: var(--padding);
	min-width: 25% !important;
}

@media only screen and (max-width: 600px) {
	nav.top .left,
	nav.top .right {
		min-width: unset;
	}
}

nav.top .left {	
	min-width: 0;
	overflow: hidden;
}

nav.top .right {
	justify-content: flex-end;
}

nav.top .center {
	display: flex;
	justify-content: center;
	min-width: 25%;
}

nav .nav-links {
	display: flex;
	font-size: var(--font-size-125);
	gap: var(--padding-300);
	list-style: none;
	padding: 0;
	margin: 0;
}

nav .nav-links a {
	color: var(--body-text-color);
	position: relative;
}

nav .nav-links a:hover {
	color: var(--link-color);
}

nav .nav-links a::after {
	border-radius: 4px;
	bottom: -4px;
	left: 0;
	content: '';
	position: absolute;
	height: 4px;
	transition: var(--transition);
	width: 100%;
}

nav .nav-links a:hover::after {
	background: var(--link-highlight-color);
}

nav .nav-links a.active::after {
	background: var(--body-text-color);
	left: calc(50% - 2px);
	opacity: .5;
	width: 4px;
}

nav .nav-links a.active:hover::after {
	background: var(--link-color);
	opacity: 1;
}

nav.top.internal button, 
nav.top.internal .button,
nav.top .hamburger {	background: transparent;
	border: none;
	border-radius: var(--border-radius-50);
	color: var(--body-text-color);
	cursor: pointer;
	display: flex;
	font-size: var(--font-size);
	font-weight: bold;
	min-width: 36px;
	padding: var(--padding-50) var(--padding);
	transition: var(--transition);
	-webkit-appearance: none;
}

nav.top.internal button:hover,
nav.top.internal .button:hover,
nav.top .hamburger:hover {
	background: rgba(9, 1, 208, 0.1);
	color: var(--body-text-color);
}

nav.top.internal button.primary,
nav.top.internal .button.primary {
	background: var(--cta-primary-background);
	color: var(--cta-primary-text);
}

nav.top.internal button.primary:hover,
nav.top.internal .button.primary:hover {
	background: var(--cta-primary-background-hover);
}

nav.top.internal button path {
	fill: var(--icon-button-fill);
}

nav.top.internal button:hover path {
	fill: var(--icon-button-fill);
}

nav.top.internal button.active {
	background: var(--icon-button-fill);
	color: var(--h2-text) !important;
}

nav.top.internal button.active path {
	fill: var(--white);
}

nav.top.internal .avatar path {
	fill: var(--body-text-color);
}

nav.top .links {
	display: flex;
	gap: var(--padding-50);
}

@media only screen and (max-width: 600px) {
	nav.top.external button,
	nav.top.external .button  {			
		padding: var(--padding-50) var(--padding-150);
	}
}

@media only screen and (max-width: 600px) {
	.button.my-courses  {			
		gap: var(--padding-50);
		padding: var(--padding-50) var(--padding) !important;
	}
}

nav.top .trial-badge-wrapper {
	padding: var(--padding);
}

nav.top .trial-badge-wrapper .trial-badge {
	display: inline-block;
}

nav.top .trial-badge {
	background: var(--h1-background);
	border-radius: var(--border-radius-200);
	color: var(--h1-text);
	padding: var(--padding-50) var(--padding);
	font-weight: bold;
}

nav.top .trial-badge.expired {
	background: transparent;
	border: 1px solid var(--cta-primary-background);
	color: var(--cta-primary-background);
}

nav.top .badge {
	border: 1px solid var(--link-color);
	color: var(--link-color);
	border-radius: var(--border-radius-50);
	max-width: fit-content;
	font-weight: bold;
	padding: var(--padding-25) var(--padding-50);
	text-transform: uppercase;
}

.menu-item-badge {
	background: var(--h2-background);
	border-radius: var(--border-radius-200);
	color: var(--white);
	font-size: var(--font-size-75);
	font-weight: bold;
	line-height: 100%;
	margin: 0 var(--padding-50);
	padding: var(--padding-25) var(--padding-50);
	text-transform: uppercase;
}

.alert-banner {
	background: var(--orange);	
	box-shadow: rgba(0, 0, 0, .3) 0px 1px 2px 0px;
	color: var(--white);
	display: flex;
	font-weight: bold;
	justify-content: center;
	position: fixed;
	padding: var(--padding);
	width: 100%;
	z-index: 100000;
}


/* Breadcrumbs */

.breadcrumbs {
	align-items: center;
	display: flex;
	gap: var(--padding);
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}

@media only screen and (max-width: 600px) {
	.breadcrumbs {		
		gap: var(--padding-50);
	}
}

.breadcrumbs li {
	align-items: center;
	display: flex;
	overflow: hidden;
}

@media only screen and (max-width: 600px) {
	.breadcrumbs li.hidden {	
		display: none;
	}
}

.breadcrumbs li.compact {
	min-width: fit-content; 
}

.breadcrumbs li.compact a {
	min-width: 24px;
}

@media only screen and (max-width: 600px) {
	.breadcrumbs li.compact .collapsible-text {	
		display: none;
	}
}

.breadcrumbs li a,
.breadcrumbs li button {
	align-items: center;
	background: transparent;
	border: none;
	border-radius: var(--border-radius-50);
	font-weight: normal;
	color: var(--body-text-color);
	display: flex;
	min-width: 0;
	gap: var(--padding-50);
	padding: var(--padding-50);
	transition: var(--transition);
	text-align: left;
}

@media only screen and (max-width: 600px) {
	.breadcrumbs li button {
		padding: var(--padding-50) !important;
	}
}

.breadcrumbs li a span,
.breadcrumbs li button span {	
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.breadcrumbs a:hover,
.breadcrumbs button:hover {
	background: var(--blue-10-transparent);
	color: var(--link-color);
}

.breadcrumbs a:hover path,
.breadcrumbs button:hover path {
	fill: var(--blue);
	fill-opacity: 1;
}

.breadcrumbs a.active,
.breadcrumbs button.active,
.breadcrumbs a.active:hover,
.breadcrumbs button.active:hover {
	background-color: var(--link-color);
	color: var(--white);
}

.breadcrumbs a.active path,
.breadcrumbs button.active path,
.breadcrumbs a.active:hover path,
.breadcrumbs button.active:hover path {
	fill: var(--white);
	fill-opacity: 1;
}

.breadcrumbs li .separator {
	padding-left: var(--padding);
}

@media only screen and (max-width: 600px) {
	.breadcrumbs li .separator {	
		padding-left: var(--padding-50);
	}
}

.breadcrumbs li .separator path {
	fill: var(--breadcrumbs-divider);
}

.dropdown-arrow {
	flex: 0;
	min-width: 12px;
}


/* Headings */

h1 {
	background: var(--h1-background);
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	display: inline;
	color: var(--h1-text);
	font-size: var(--font-size-300);
	padding: 0 var(--padding-50);
	margin: 0;
}

@media only screen and (max-width: 600px) {
	h1 {		
		font-size: var(--font-size-225);
	}
}

h3 {
	margin: 0;
}

.blurb h2 {
	background: var(--h1-background);
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	display: inline;
	color: var(--h1-text);
	font-size: var(--font-size-225);
	padding: 0 var(--padding-50);
	margin: 0;
}

.blurb h2 a {
	color: var(--h1-text);
}

.prose h2 {
	background: var(--h2-background);
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	display: inline-block;
	color: var(--h2-text);
	font-size: var(--font-size-200);
	padding: 0 var(--padding-50);
	margin: var(--padding) 0;
}

.prose h3, 
.product-details h2 {
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	display: inline;
	font-size: var(--font-size-150);
	line-height: var(--font-size-150);
	margin: var(--padding) 0;
	padding: 0;
}

a.scroll-offset {	
	scroll-margin-top: 100px;
}


/* Paragraphs */

.prose p {
	padding: var(--padding-50) 0;
}

p.intro {
	font-weight: bold;
}

.blurb {
	max-width: 700px;
}

@media only screen and (max-width: 600px) {
	.blurb.x-padding {	
		padding: 0 var(--padding-150);
	}
}

.blurb p {
	font-size: var(--font-size-125);
	line-height: var(--line-height-125);
	margin: var(--padding-125) 0;
}


/* Spans */

span.highlighted {
	background: var(--h1-background);
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	color: var(--h1-text);
	font-weight: bold;
	padding: 0 var(--padding-50);
}

.explanation {
	align-items: center;
	color: var(--grey);
	display: flex;
	font-size: var(--font-size-75);
	gap: var(--padding-50);
	font-weight: bold;
	padding-left: 18px;
	position: relative;
}

.explanation::before {
	content: url('/static/images/info-icon.svg');
	display: block;
	height: 12px;
	left: 0;
	position: absolute;
	top: 2px;
	width: 12px;
}

.explanation .icon path {
	fill: var(--grey);
}


/* Forms */

form {
	display: flex;
	flex-direction: column;
	gap: var(--padding);
}

@media only screen and (max-width: 600px) {
	form {	
		width: 100%;
	}
}

form .form-row {
	display: flex;	
	flex: 1;
	flex-direction: column;
	gap: var(--padding-50);
}

form .form-row.padded {
	padding-top: var(--padding);
}

form .form-row label {
	font-weight: bold;
	font-size: var(--font-size-75);
}

form .form-row input {
	width: 100%;
}


/* Errors */

.error-text {
	background: var(--red-10-transparent);
	border-radius: var(--border-radius-50);
	padding: var(--padding);
	color: var(--red);
	word-break: break-word;
}


/* Skeleton loader */

.skeleton {
	animation: pulse 2000ms infinite;
	border: none !important;
	box-shadow: none !important;
	color: transparent !important;
	cursor: default;
	opacity: .5;
}

.skeleton.paragraph {
	height: 100px;
	width: 100%;
}

.skeleton.paragraph.short {
	height: 60px;
}

.skeleton.paragraph.narrower {
	width: 90%;
}

.skeleton.paragraph.narrow {
	width: 70%;
}


/* Icon wrappers */

.icon {
	height: 24px;
	width: 24px;
}

.icon path {
	fill: var(--icon-button-fill);
}

.body-fill path {
	fill: var(--body-text-color);
}

.icon.color path {
	fill: revert-layer;
}

.icon.large {
	height: 64px;
	width: 64px;
}

.icon.small-med {
	flex: 0;
	min-height: 16px;
	height: 16px;
	width: 16px;
	min-width: 16px;
}

.icon.small {
	height: 12px;
	width: 12px;
}

.icon-bullet {
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: var(--padding-50);
}

.icon.orange path {
	fill: var(--orange);
}


/* Cards */

.card-wrapper {
	display: flex;
	flex-direction: row;
	gap: var(--padding-150);
}

@media only screen and (max-width: 600px) {
	.card-wrapper {			
		flex-direction: column;
	}
}

.card {
	align-items: flex-start;
	background: var(--card-background);
	border-radius: var(--border-radius-150);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	gap: var(--padding);
	padding: var(--padding-200);
}

@media only screen and (max-width: 600px) {
	.card {			
		padding: var(--padding-150);
	}
}

.card.disabled {
	opacity: .5;
}

.card.secondary {
	background: var(--card-background-secondary);
}


.card.info h3 {
	font-size: var(--font-size-125);
}

.card.info button,
.card.info .button {
	width: 100%;
}

.card.info p {
	margin: var(--padding) 0 0 0;
}

.card h2 {
	margin: var(--padding) 0;
}

.card h3 {
	font-size: var(--font-size-150);
	line-height: var(--font-size-150);
	margin: 0;
}

.card h4,
.product-details h2,
.reviews h2 {
	background: var(--charcoal);
	color: var(--white);
	display: inline;
	font-size: var(--font-size);
	line-height: var(--font-size);
	margin: 0;
	padding: 0 var(--padding-50);
}

.card p {
	margin: var(--padding) 0;
}

.signup-success {
	border-top-color: var(--turquoise);
}

@media only screen and (max-width: 600px) {
	.signup-success {
		padding: var(--padding-150);
	}
}

.card.signup-success p {
	max-width: 45ch;
}

.card.purchases {
	min-height: 198px;
}


/* Progress bar */

.progress-wrapper {
	background: rgba(28, 30, 33, 0.10);
	height: 4px;
	width: 100%;
}

.progress-wrapper .progress-bar {
	border-radius: 0 4px 4px 0;
	background: var(--orange);
	float: left;
	height: 4px;
	min-width: 6px;
	transition: var(--transition-slow);
}

.progress-wrapper .progress-bar.complete {
	border-radius: 0;
	background: var(--turquoise);
}

.progress-wrapper.lesson-progress {
	position: fixed;
	top: 60px;
	z-index: 50;
}


/* Courses and lessons */

.courses,
.lessons {
	display: flex;	
	flex-direction: column;
	flex-wrap: wrap;
	gap: var(--padding-150);
}

@media only screen and (min-width: 600px) {
	.courses,
	.lessons {				
		flex-direction: row;
	}
}

.courses .course-card, 
.lessons .lesson-card {
	align-items: flex-start;
	background: var(--card-background);
	border-radius: var(--border-radius-150);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	flex: 1 0 0;
	flex-direction: column;
	justify-content: space-between;
	max-width: calc(50% - (var(--padding-150) * 2));
	overflow: hidden;	
}

@media only screen and (max-width: 600px) {
	.courses .course-card, 
	.lessons .lesson-card {	
		align-items: stretch;
		max-width: 100%;
	}
}

.courses .course-card {	
	border-bottom-left-radius: var(--border-radius-300);
	border-bottom-right-radius: var(--border-radius-300);
	gap: var(--padding);
	min-height: 300px;
	padding: var(--padding-150);
}

@media only screen and (max-width: 600px) {
	.courses .course-card {	
		min-height: auto;	
	}
}

@media only screen and (max-width: 600px) {
	.courses .course-card.skeleton {	
		min-height: 300px !important;
	}
}

.course-header {
	align-items: center;
	gap: var(--padding);
	justify-content: space-between;
	display: flex;
	position: relative;
	width: 100%;
}

.lessons .lesson-card {
	flex-direction: row;
	min-height: 170px;
	min-width: calc(50% - (var(--padding-150) / 2));
}

@media only screen and (max-width: 600px) {
	.lessons .lesson-card  {	
		min-height: auto;	
		min-width: 100%;
	}
	
	.lessons .lesson-card.skeleton {
		min-height: 170px;
	}
}

.courses .course-card .cover,
.lessons .lesson-card .cover {
	background: #ebe9e5;
	flex: 1;
	height: 150px;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.courses .course-card .cover,
	.lessons .lesson-card .cover {	
		flex: auto;
	}
}

.courses .course-card .cover img {
	border-radius: var(--border-radius-50);
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.lessons .lesson-card .lesson-icon-wrapper {
	align-items: center;
	background: var(--card-background);
	border-right: 1px solid var(--card-divider-border);
	display: flex;
	justify-content: center;
	min-height: 100%;
	width: 140px;
}

@media only screen and (max-width: 1050px) {
	.lessons .lesson-card .lesson-icon-wrapper {	
		width: 100px;
	}
}

@media only screen and (max-width: 1050px) {
	.lessons .lesson-card .lesson-icon-wrapper .icon.large {	
		height: 48px;
		width: 48px;
	}
}

.courses .course-card .course-label,
.lessons .lesson-card .lesson-label {
	display: flex;
	justify-content: space-between;
	height: 100%;
	padding: var(--padding-150);
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.courses .course-card .course-label,
	.lessons .lesson-card .lesson-label {	
		padding: var(--padding-150);
	}
}

.courses .course-card .course-label {
	align-items: center;
	gap: var(--padding-150);
	padding: 0 var(--padding);
}

.lessons .lesson-card .lesson-label {
	flex-direction: column;
	gap: var(--padding);
}

.lessons .lesson-card .lesson-label p {
	font-size: var(--font-size);
}

.courses .course-card .course-label h2,
.lessons .lesson-card .lesson-label h2 {
	margin: 0;
	padding: 0;
}

.lessons .lesson-card .lesson-label .lesson-info {
	align-items: flex-start;
	align-content: baseline;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: var(--padding-25);
	width: 100%;
}

.lessons .lesson-card .lesson-label .lesson-info .lesson-info-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.courses .course-card .course-buttons {
	align-items: stretch;
	display: flex;
	gap: var(--padding);
	padding-top: 0;
	width: 100%;
}

.courses .course-card .course-buttons button,
.courses .course-card .course-buttons a,
.lessons .lesson-card .lesson-label button {
	min-width: 50%;
	width: 100%;
}

.lesson p,
.course p {
	font-size: var(--font-size-125);
	line-height: var(--line-height-125);
	margin: 0;
	max-width: 700px;
}

.course-card.disabled .cover {
	cursor: not-allowed;
	opacity: .5;
}

.course-card.disabled .course-label {
	cursor: not-allowed;
	opacity: .5;
}

.course-card.disabled .progress-donut-wrapper {
	cursor: not-allowed;
}


/* Lesson */

.lesson {
}

.lesson ul, 
.lesson ol {
	font-size: var(--font-size-125);
	line-height: var(--line-height-125);
	margin: 0;
	padding: 0 var(--padding-200);
}

.lesson li {	
	padding: var(--padding-25) 0;
}

.lesson ul li {
	max-width: 700px;
	list-style: none;
	position: relative;
}

.lesson ul li::before {
	background: var(--h1-background);
	content: "";
	display: block;
	height: 8px;
	left: -18px;
	position: absolute;
	top: 14px;
	width: 8px;
}

.lesson ol li {
	margin: 0 var(--padding-200);
}

.conversation button.secondary {
	border-color: var(--white);
	color: var(--white);
}

.conversation button.secondary path {
	fill: var(--white);
}

.conversation button:hover {
	background: var(--white);
	color: var(--blue);
}

.conversation button:hover path {
	fill: var(--blue);
}

.lesson .callout {
	align-items: flex-start;
	border-radius: 12px;
	border-top: 4px solid var(--turquoise);
	border-right: 1px solid var(--turquoise);
	border-bottom: 1px solid var(--turquoise);
	border-left: 1px solid var(--turquoise);
	background: var(--callout-tip-background);
	box-shadow: 0px 1px 2px 0px rgba(28, 162, 178, 0.25);	
	color: var(--callout-tip-text);
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: var(--padding-50);
	max-width: 700px;
	padding: var(--padding);
}

.lesson .callout.task {
	background: var(--callout-task-background);
	border-color: var(--blue);
	box-shadow: 0px 1px 2px 0px rgba(11, 0, 209, 0.25);
	color: var(--callout-task-text);
}

.lesson .callout p {
	font-size: var(--font-size);
	line-height: var(--line-height-110);
	margin: 0;
	padding: 0;
}

.lesson .nav-buttons {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	gap: var(--padding-150);
	padding: var(--padding) 0;
}

@media only screen and (min-width: 600px) {
	.lesson .nav-buttons {	
		flex-direction: row;
		padding: var(--padding-300) 0;
	}
}

@media only screen and (max-width: 600px) {
	.lesson .nav-buttons button,
	.lesson .nav-buttons .button {	
		width: 100%;
	}
}


/* Dropdown */

.dropdown {
	background: var(--background-color);
	border-radius: var(--border-radius);
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.10), 0px 10px 20px 1px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	gap: 2px;
	justify-content: flex-start;
	min-width: 320px;
	max-height: calc(100vh - 60px);
	opacity: 0;
	overflow-x: scroll;
	overflow-y: hidden;
	pointer-events: none;
	position: fixed;
	left: var(--padding);
	top: 52px;
	transform: scale(25%);
	transform-origin: 0 0;
	transition: var(--transition);
	z-index: 50000;
}

@media only screen and (max-width: 600px) {
	.dropdown {	
		border-radius: 0;
		bottom: 0 !important;
		left: 0 !important;
		min-height: calc(100vh - 60px);
		position: fixed;
		right: 0 !important;
		top: 60px !important;
		transform: scaleY(25%);
	}
}

.dropdown.lesson-dropdown {
	left: auto;
	min-width: 300px;
	top: 50px;
}

@media only screen and (max-width: 600px) {
	.dropdown.lesson-dropdown {
		height: calc(100vh - 60px) !important;
		min-height: unset;
	}
}

@supports (height: 100dvh) {
	@media only screen and (max-width: 600px) {
		.dropdown.lesson-dropdown {
			height: calc(100dvh - 60px) !important;
		}
	}
}

.dropdown.lesson-dropdown .icon {
	height: 20px !important;
	width: 20px !important;
}

.dropdown.user-dropdown {
	left: auto;	
	right: var(--padding);
	top: 57px;
	transform-origin: 100% 0;
}

@media only screen and (max-width: 600px) {
	.dropdown.nav-dropdown {	
		bottom: 0;
	}
}

.dropdown.exercise-dropdown {
	border-radius: var(--border-radius);
	position: absolute !important;
	left: auto !important;
	right: 0;
	top: 45px !important;
	transform-origin: 100% 0 !important;
	min-height: min-content;
	min-width: max-content;
}

@media only screen and (max-width: 600px) {
	.dropdown.exercise-dropdown {
		bottom: 0;
	}
}

.dropdown.course-options-dropdown {
	border-radius: var(--border-radius);
	position: absolute !important;
	left: auto !important;
	right: 0;
	top: 48px !important;
	transform-origin: 100% 0 !important;
	min-height: min-content;
	min-width: unset;
}

@media only screen and (max-width: 600px) {
	.dropdown.course-options-dropdown {
		bottom: 0;
	}
}

.dropdown.visible {
	display: flex;
	opacity: 1;
	overflow-y: scroll;
	pointer-events: all;
	transform: scale(100%) !important;
}

.action-menu-wrapper {
	position: fixed;
	background: transparent;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	pointer-events: none;
	transition: var(--transition);
	top: 0;
	z-index: 20000;
}

@media only screen and (max-width: 600px) {
	.action-menu-wrapper {		
		background: rgba(28, 30, 33, .4);
	}
}

.action-menu-wrapper.visible {
	opacity: 1;
	pointer-events: all;
}

@media only screen and (max-width: 600px) {
	.dropdown.action-menu {
		background: var(--card-background);
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		bottom: -100px !important;
		height: min-content;
		gap: var(--padding);
		padding: var(--padding-150) !important;
		padding-bottom: var(--padding-300) !important;
		position: fixed !important;
		transform: scale(100%) !important;
		top: unset !important;
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.dropdown.action-menu.visible {
		bottom: 0 !important;
	}
}

.dropdown ul {	
	background: var(--background-color);
	display: flex;
	flex-direction: column;
	gap: 1px;
	list-style: none;
	margin: 0;
	padding: 0;
}

@media only screen and (max-width: 600px) {
	.dropdown.action-menu ul {
		border-radius: var(--border-radius-50);
		overflow: hidden;
	}
}

.dropdown ul li {
	background: var(--card-background);
	display: flex;
	font-size: var(--font-size);
	min-width: fit-content;
	gap: var(--padding-50);
	line-height: normal;
}

@media only screen and (max-width: 600px) {
	.dropdown.action-menu ul li {
		background: var(--card-background-secondary);
	}
}

.dropdown ul li.danger {
	color: var(--red);
}

.dropdown ul li.danger .icon path {
	fill: var(--red);
}

.dropdown ul li.padded {	
	padding: var(--padding);
}

.dropdown ul li.active a {
	font-weight: bold;
}

.dropdown ul li.linkless {
	align-items: center;
	cursor: pointer;
	max-height: 44px;
	padding: var(--padding);
	padding-right: var(--padding-150);
}

.dropdown ul li.linkless:hover {
	background: var(--link-highlight-color);
	color: var(--link-color);
}

.dropdown ul li a {
	align-items: center;
	border-radius: 0;
	color: var(--body-text-color);
	display: flex;
	gap: var(--padding-50);
	padding: var(--padding);
	padding-right: var(--padding-150);
	max-height: 44px;
	flex: 1;
}

.dropdown ul li a:hover {
	background: var(--link-highlight-color);
	color: var(--link-color);
}

.dropdown ul li a.link-color {
	color: var(--link-color) !important;
}

.dropdown ul li.danger:hover {
	background: var(--red-10-transparent);
	color: var(--red);
}

.dropdown-options-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: var(--padding);
}

.dropdown-shader {
	background: rgba(0, 0, 0, 0.6);
	bottom: 0;
	position: fixed;
	left: 0;
	opacity: 0;
	pointer-events: none;
	right: 0;
	top: 60px;
	transition: var(--transition);
	z-index: 1000;
}

@media only screen and (min-width: 600px) {
	.dropdown-shader.visible {
		opacity: 1;
		pointer-events: all;
	}
}


/* Toggle buttons */

.toggle-buttons-wrapper {
	display: flex;
	flex: 1;
	gap: var(--padding-50);
}

button.toggle,
.button.toggle {
	background: transparent;
	border: 1px solid var(--toggle-button-outline) !important;
	border-radius: var(--border-radius-50);
	color: var(--body-text-color);
	font-weight: normal !important;
	padding: var(--padding-50);
	flex: 1;
}

button.toggle:hover,
.button.toggle:hover {
	background: var(--link-highlight-color);
}

button.toggle.selected,
.button.toggle.selected {
	background: var(--link-highlight-color);
	font-weight: bold !important;
}


/* Account page */

.account-section h2 {
	margin: 0;
	padding: 0;
}

.container.account .skeleton {
	min-height: 200px;
}

.centred-content {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.centred-content.padded {
	padding: var(--padding) 0;
}

@media only screen and (max-width: 600px) {
	.centred-content.padded {
		padding: 0;
	}
}

.full-height {
	flex-grow: 1;
	min-height: 100% !important;
}

.rows {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
}

@media only screen and (max-width: 600px) {
	.rows {	
		gap: var(--padding);
		width: 100%;
	}
}

.row {
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	gap: var(--padding);
	padding: var(--padding) 0;
	min-width: 300px;
}

@media only screen and (max-width: 600px) {
	.row {	
		flex-direction: column;
		gap: var(--padding);
		min-width: 0;
		padding: var(--padding-25) 0;
		width: 100%;
	}
	
	dialog .row button,
	dialog .row input,
	dialog .row a {
		width: 100% !important;
	}
	
	dialog .row button.link {
		width: auto !important;
	}
}

.row.centred {
	align-items: center;
}

.row.spaced {
	justify-content: space-between;
	width: 100%;
}

.row.align-right {
	justify-content: flex-end;
}

.vertical-row-content {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	padding: var(--padding) 0;
	width: 300px;
}

@media only screen and (max-width: 600px) {
	.vertical-row-content {	
		width: 100%;
	}
}

.vertical-row-content.full-width {
	width: 100%;
}

.vertical-row-content.slim-gap {
	gap: var(--padding-50);
}

.text-padding {
	padding-top: 8px;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.row-label {
	font-weight: bold;
	padding: 13px 0;
}

@media only screen and (max-width: 600px) {
	.row-label {
		padding: 0;
	}
}

.row-content {
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	padding: 13px 0;
	width: 300px;
}

@media only screen and (max-width: 600px) {
	.row-content {
		padding: 0;
		width: 100%;
	}
}

input[type=text].account-form,
input[type=email].account-form,
input[type=password].account-form, 
input[type=number].account-form {
	font-size: var(--font-size);
	padding: var(--padding);
	width: 300px;
}

@media only screen and (max-width: 600px) {
	input[type=text].account-form,
	input[type=email].account-form,
	input[type=password].account-form, 
	input[type=number].account-form {
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.card button,
	.card .button {
		width: 100%;
	}
}

table {
	border-collapse: collapse;
}

table.full-width {
	width: 100%;
}

table.pretty th,
table.pretty td {
	text-align: left;
	padding: var(--padding);
}

table.pretty th {
	border-bottom: 1px solid var(--card-divider-border);
}

td.align-right {
	display: flex;
	justify-content: flex-end;
	text-align: right;
}

.status-widget {
	align-items: center;
	display: flex;
	gap: var(--padding-50);
	transition: var(--transition)
}

.status-widget.success {
	color: var(--turquoise);
}

.status-widget.hidden {
	opacity: 0;
}


/* Dialogs */

.dialog-shader {
	align-items: center;
	background: rgba(28, 30, 33, .4);	
	backdrop-filter: blur(30px);
	bottom: 0;
	display: flex;
	position: fixed;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	left: 0;
	right: 0;
	top: 0;
	transition: var(--transition);
	z-index: 20000;
}

.dialog-shader.light {
	backdrop-filter: none;
	background: rgba(28, 30, 33, .2);	
}

.dialog-shader.visible {
	opacity: 1;
	pointer-events: all;
}

.dialog-shader.show-nav {
	top: 60px;
	z-index: 5000;
}

.dialog {	
	background: var(--card-background);
	border: none;
	border-radius: var(--border-radius-150);
	color: var(--body-text-color);
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	margin-bottom: -40px;
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.10), 0px 20px 40px 0px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
	padding: var(--padding-200);
	transition: var(--transition);
	width: 400px;
}

.dialog.large {
	width: 600px;
}

.dialog-shader.visible .dialog {
	margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
	.dialog {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		height: calc(100% - 20px);
		position: fixed;
		width: 100% !important;
		bottom: -100%;
	}
}

@media only screen and (max-width: 600px) {
	.dialog-shader.visible .dialog {
		bottom: 0;
	}
}

.dialog form {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: var(--padding-200);
}

.dialog input[type=password],
.dialog input[type=email] {
	width: 100%;
}

.dialog h2 {
	background: transparent;
	font-size: var(--font-size-150);
	margin: 0;
	padding: 0;
	color: var(--body-text-color);
}

.dialog p {
	font-size: var(--font-size) !important;
	line-height: unset !important;
}

.dialog .rows {
	gap: var(--padding-200);
}

@media only screen and (max-width: 600px) {
	.dialog .dialog-content {
		flex-grow: 1;
		max-height: 100%;
		overflow: scroll;
	}
}

.dialog .rows.anchored-buttons {
	max-height: min-content;
}

.dialog .row {
	padding: 0;
	width: 100%;
}

.dialog .prose {
	display: flex;
	gap: var(--padding);
	flex-direction: column;
}

.dialog .prose p {
	margin: 0;
	padding: 0;
}

.dialog .vertical-row-content {
	max-width: 100%;
	padding: 0;
	width: 100%;
}


/* Payments */

.payment-status-wrapper {
	align-items: center;
	backdrop-filter: blur(20px);
	background: var(--card-background-secondary);
	bottom: 0;
	display: flex;
	flex-direction: column;
	gap: var(--padding-500);
	justify-content: center;
	left: 0;
	padding: var(--padding-300);
	position: absolute;
	right: 0;
	text-align: center;
	top: 61px;
	width: 100%;
	z-index: 5000;
}

.payment-status-wrapper p {
	text-wrap: balance;
}

.payment-status-wrapper h2 {
	font-size: var(--font-size-125);
	font-weight: bold;
}

.payment-status-content {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: var(--padding);
}

.payment-status-buttons {
	display: flex;
	gap: var(--padding);
}

@media only screen and (max-width: 600px) {
	.payment-status-buttons {
		flex-direction: column;
		gap: var(--padding-200);
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
	.payment-status-buttons button {
		width: 100%;
	}
}


/* Keyboard */

#keyboard {
	background: var(--nav-background);	
	backdrop-filter: blur(30px);
	border-radius: var(--border-radius);
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.10), 
		0px 20px 40px 0px rgba(0, 0, 0, 0.25), 
		0px 1px 2px 0px rgba(0, 0, 0, 0.10);
	display: flex;
	gap: var(--padding-50);
	justify-content: space-between;
	padding: var(--padding);
	position: fixed;
	left: 50%;
	bottom: -100px;
	transform: translate(-50%, 0);
	transition: var(--transition);
}

#keyboard.visible {	
	bottom: 12px;
}

@media only screen and (max-width: 600px) {
	#keyboard.visible {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transform: inherit;
	}
}

@supports (height: 100svh) {
	@media only screen and (max-width: 600px) {
		#keyboard.visible {
			bottom: unset;
			top: calc(100svh - 66px) !important;
		}
	}
}

#keyboard .key-shader {
	content: '';
	display: none;
	height: 100%;
	background: linear-gradient(90deg, var(--card-background-transparent) 0%, var(--card-background) 100%);
	position: absolute;
	right: 43px;
	top: 0;
	width: 32px;
}

@media only screen and (max-width: 600px) {
	#keyboard .key-shader {
		display: block
	}
}

#keyboard .keys {
	display: flex;
	gap: var(--padding-50);
	overflow-x: scroll;
	position: relative;
}

#keyboard .keys .key-wrapper {
	display: flex;
	gap: var(--padding-50);
	min-width: 500px;
}

#keyboard button {
	background: transparent;
	border: 1px solid var(--link-highlight-color);
	color: var(--link-color);
	font-size: var(--font-size-125);
	height: 42px;
	width: 42px;
	padding: 0;
	border-radius: var(--border-radius-50) !important;
}

#keyboard button:hover {
	background: var(--link-highlight-color);
}

.toolbar-buttons {
	display: flex;
	gap: var(--padding);
	position: relative;
}

button.specialchars {
	background: transparent;
	border-radius: var(--border-radius-50);
	border: 1px solid var(--link-highlight-color);
	height: 42px;
	padding: 0;
	width: 42px;
}

button.specialchars path {
	fill: var(--icon-button-fill);
}

button.toolbar {
	background: transparent;
	border-radius: var(--border-radius-50);
	border: 1px solid var(--link-highlight-color);
	height: 42px;
	min-width: 42px;
	padding: 0;
}

button.toolbar path {
	fill: var(--body-text-color);
}

button.specialchars:hover,
button.toolbar:hover,
button.toolbar.active {
	background: var(--h1-background);
}

button.specialchars:hover path,
button.toolbar:hover path,
button.toolbar.active path {
	fill: var(--h1-text);
}

button.toolbar.round {
	border-radius: 48px;
}

button.specialchars.close {
	border: none !important;
}

button.specialchars.close:hover {
	background: transparent !important;
}

button.specialchars.close:hover path {
	fill: var(--icon-button-fill);
}

/* Dialog – Report a problem */

.report-problem-dialog {
	bottom: 12px;
	gap: 0;
	height: 460px;
	margin: 0;
	opacity: 0;
	padding: 0;
	position: fixed;
	pointer-events: none;
	right: 12px;	
	transform: scale(25%);
	transform-origin: 100% 100%; 
	transition: var(--transition);
	width: 460px;
	z-index: 50000;
}

@media only screen and (max-width: 600px) {
	.report-problem-dialog {
		bottom: 0;
		right: 0;		
		transform: scale(100%) translateY(100px);
		width: 100%;
	}
}

.report-problem-dialog.visible {
	opacity: 1;
	pointer-events: all;
	transform: scale(100%);
}

@media only screen and (max-width: 600px) {
	.report-problem-dialog.visible {
		opacity: 1;
		transform: scale(100%);
	}
}

.report-problem-dialog-header {
	align-items: center;
	display: flex;
	gap: var(--padding);
	justify-content: space-between;
	padding: var(--padding-150);
}

.report-problem-dialog-header h2 {
	font-size: var(--font-size-125);
}

.report-problem-screen {
	display: none;
}

.report-problem-screen.visible {
	display: block;
}

ul.wizard-options {
	background: var(--card-divider-border);
	border: 1px solid var(--card-divider-border);
	border-radius: var(--border-radius);
	display: flex;
	flex-direction: column;
	gap: 1px;
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

ul.wizard-options li {
	align-items: center;
	background: var(--card-background);
	color: var(--link-color);
	display: flex;
	gap: var(--padding);
	justify-content: space-between;
	padding: var(--padding-150) var(--padding);
}

ul.wizard-options li:hover {
	background: var(--h1-background);
	color: var(--h1-text);
	cursor: pointer;
}

ul.wizard-options li path {
	fill: var(--link-color);
}

ul.wizard-options li:hover path {
	fill: var(--h1-text);
}

.wizard-option-label {
	align-items: center;
	display: flex;
	gap: var(--padding);
}

.report-problem-dialog form {
	gap: var(--padding-25);
}

.report-problem-dialog .form-row {
	border-top: 1px solid var(--card-divider-border);
	display: flex;
	flex-direction: column;
	gap: var(--padding);
	padding: var(--padding-150);
}

.report-problem-dialog .form-row.no-border {
	border-top: none;
}

.report-problem-dialog label {
	font-size: var(--font-size) !important;
}

.report-problem-dialog textarea {
	font-size: var(--font-size);
	height: 150px;
	width: 100%;
}

.report-problem-dialog p {
	margin: 0;
	line-height: var(--line-height-110);
}


/* Onboarding */

.dialog.onboarding {
	display: flex;
	padding: var(--padding-400);
	width: 800px;
	min-height: 600px;
}

@media only screen and (max-width: 800px) {
	.dialog.onboarding {
		border-radius: 0;
		padding: 0;
		min-height: auto;
		height: 100%;
		overflow-y: scroll;
		max-width: 100%;
		top: 0;
	}
}

.dialog.onboarding .prose {
	text-align: center;
}

@media only screen and (max-width: 800px) {
	.dialog.onboarding .prose {		
		text-align: left;
	}
}

.dialog.onboarding .dialog-content {
	display: flex;
	flex-direction: column;
	gap: var(--padding-200);
}
	
@media only screen and (max-width: 800px) {
	.dialog.onboarding .dialog-content {
		padding: var(--padding-200);
	}
}
	
@media only screen and (max-width: 800px) {
	.dialog.onboarding .anchored-buttons {
		padding: var(--padding-200);
		width: 100%;
	}
}

.dialog.onboarding h1 {
	animation: welcome 1000ms;
	animation-timing-function: ease-in-out;
	background: linear-gradient(90deg, var(--h1-background), var(--h1-background-transparent));
	background-clip: text;
	font-size: 72px;
	font-style: italic;
	text-fill-color: transparent;
	transition: 0.5s;
	-webkit-text-fill-color: transparent;
}

.dialog.onboarding p {	
	line-height: var(--line-height-110);
	text-align: center;
	max-width: 60ch;
}

@media only screen and (max-width: 800px) {
	.dialog.onboarding p {		
		text-align: left;
	}
}

@media only screen and (max-width: 800px) {
	.dialog.onboarding button {
		width: 100%;
	}
}

@keyframes welcome {
	0% {
		opacity: 0;
		transform: translateX(5%);
	}
	100% {		
		opacity: 1;
	}
}

.dialog.onboarding .screen {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: var(--padding);
	justify-content: space-between;
	max-width: 100%;
	max-height: 100%;
}

.dialog.onboarding .screen.splash {
	gap: var(--padding-400);
	padding: var(--padding-200);
	justify-content: center;
}

.onboarding-options-wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: var(--padding);
}

@media only screen and (max-width: 800px) {
	.onboarding-options-wrapper {
		width: 100%;
	}
}

ul.onboarding-options {
	align-items: flex-start;
	display: flex;
	gap: var(--padding);
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
}

ul.onboarding-options.vertical {
	flex-direction: column;
	width: 280px;
}

@media only screen and (max-width: 800px) {
	ul.onboarding-options {
		flex-direction: column;
		width: 100%;
	}
}

ul.onboarding-options li {
	align-items: center;
	border: 1px solid var(--grey);
	border-radius: var(--border-radius-50);
	display: flex;
	cursor: pointer;
	gap: var(--padding);
	min-height: 60px;
	width: 100%;
	padding: var(--padding);
}

ul.onboarding-options li:hover {
	border-color: var(--field-focus-color);
	color: var(--field-focus-color);
	background: var(--blue-5-transparent);
}

ul.onboarding-options li.active {	
	border: 1px solid var(--field-focus-color);
	background: var(--blue-10-transparent);
	color: var(--link-color);
	font-weight: bold;
}

ul.onboarding-options li .radio {
	align-items: center;
	background: var(--card-background);
	border: 1px solid var(--grey);
	border-radius: 48px;
	display: flex;
	height: 24px;
	justify-content: center;
	width: 24px;
}

ul.onboarding-options li .radio .fill {
	background: var(--field-focus-color);
	border-radius: 40px;
	width: 16px;
	height: 16px;
}

ul.tips {
	display: flex;
	flex-direction: column;
	gap: var(--padding-200);
	list-style: none;
	padding: 0;
	margin: 0;
	max-width: 50ch;
}

ul.tips li {
	align-items: center;
	display: flex;
	gap: var(--padding-150);
	font-size: 90%;
}

ul.tips li .emoji {
	font-size: var(--font-size-300);
}

/* Document */

.document {
	max-width: 900px;
}

.document {	
	padding-bottom: var(--padding-400);
}

.document p {
	font-size: var(--font-size-125);
	line-height: var(--line-height-125);
	margin: var(--padding) 0;
	padding: 0;
}

.document ul {
	font-size: var(--font-size-125);
	margin: var(--padding-50) 0;
}

.document ul li {
	padding: var(--padding-25) 0;
}

.document h2 {
	background: transparent;
	display: inherit;
	font-size: var(--font-size-150);
	margin: var(--padding-200) 0 0 0;
	padding: 0;
	color: var(--body-text-color);
}

.document h3 {
	background: transparent;
	display: inherit;
	font-size: var(--font-size-125);
	margin: var(--padding-150) 0 0 0;
	padding: 0;
	color: var(--body-text-color);
}
/* Avatar */

.avatar-wrapper {
	align-items: center;
	display: flex;
	gap: var(--padding);
}

.avatar-wrapper:hover {
	color: var(--link-color) !important;
}

.avatar {
	align-items: center;
	justify-content: center;
	background: var(--charcoal-20-transparent);
	border-radius: var(--border-radius-300);
	display: flex;
	height: 32px;
	overflow: hidden;
	width: 32px;
}

.avatar-wrapper:hover .avatar {
	background: var(--blue-25-transparent);
}


/* Buttons */

button,
.button {
	align-items: center;
	border: 1px solid transparent;
	border-radius: var(--border-radius-300);
	background: var(--cta-primary-background);
	color: var(--cta-primary-text);
	cursor: pointer;
	display: flex;
	font-size: var(--font-size);
	font-weight: bold;
	gap: 12px;
	justify-content: center;
	min-width: min-content;
	padding: 12px 24px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: var(--transition);
}

button path {
	fill: var(--cta-primary-text);
}

button:disabled,
.button:disabled {
	background: transparent;
	border: 1px solid var(--cta-disabled-border);
	color: var(--cta-disabled-text);
	pointer-events: none;
	opacity: .5;
}

button:disabled path,
.button:disabled path {
	fill: var(--grey);
}

button:hover,
.button:hover {
	background: var(--cta-primary-background-hover);
}

button.primary-inverted,
.button.primary-inverted {
	background: var(--white);
	color: var(--orange);
}

button.primary-inverted path,
.button.primary-inverted path {
	fill: var(--orange);
}

button.primary-inverted:hover,
.button.primary-inverted:hover {
	color: var(--orange-700);
}

button.primary-inverted:hover path,
.button.primary-inverted:hover path {
	fill: var(--orange-700);
}

button.secondary,
.button.secondary {
	background: transparent;
	border: 1px solid var(--cta-secondary-border);
	color: var(--cta-secondary-text);
}

button.secondary path,
.button.secondary path {
	fill: var(--cta-secondary-text);
}

button.secondary:hover,
.button.secondary:hover {
	background: var(--cta-secondary-background-hover);
	color: var(--cta-secondary-text-hover);
}

button.secondary:hover path,
.button.secondary:hover path {
	fill: var(--cta-secondary-text-hover);
}

button.external-link::after,
.button.external-link::after {
	background-image: url('/static/images/icon-external-link.svg');
	background-position: center;
	background-repeat: no-repeat;
	content: '';
	display: inline-block;
	height: 16px;
	width: 16px;
}

button.icon,
.button.icon {
	padding: 0 !important;
	width: 36px;
	height: 36px;
}

button.icon:hover,
.button.icon:hover {
	background: var(--blue);
}

button.icon:hover path,
.button.icon:hover path {
	fill: var(--white);
}

button.toolbar-button {
	border-radius: var(--border-radius-50);
	padding: var(--padding-50) var(--padding);
}

button.table-inline-button {
	border-color: var(--grey-50);
	height: 24px;
	padding: 0;
	width: 24px;
}

button.table-inline-button:hover {
	border-color: var(--cta-secondary-border);
}


/* Alternative styles */

button.link,
.button.link {
	background: transparent;
	border-radius: var(--border-radius-25);
	color: var(--link-color);
	font-weight: normal;
	gap: var(--padding-50);
	margin: -4px;
	padding: var(--padding-25);
}

button.link:hover,
.button.link:hover {
	background: var(--blue-10-transparent);
}

button.link path,
.button.link path {
	fill: var(--link-color);
}

button.link.padded {
	border-radius: var(--border-radius-50);
	padding: var(--padding-50);
}

button.full-width,
a.full-width {
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.full-width-mobile {
		width: 100%;
	}
}

.audio-button-wrapper {	
	display: inline-block;
}

.audio-button-wrapper span {
	display: flex;
}

button.audio {
	background: transparent;
	display: block;
	height: 36px;
	margin: 0 var(--padding);
	overflow: hidden;
	width: 36px !important;
	padding: 0;
	position: relative;
}

button.audio path {
	fill: var(--icon-button-fill);
}

button.audio:hover {	
	background-color: var(--blue-25-transparent);
}

button.audio:disabled {
	border: none;
	opacity: .5;
}

button.audio:disabled path {
	fill: var(--grey);
}

button.audio.playing {	
	background-color: transparent !important;
}

button.audio .bar {
	stroke-dasharray: 0 100;
	transition: all 0ms !important;
}

.audio-progress {
	display: none;
}

.audio-progress.visible {
	display: block;
}

.audio-button-icon {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.button.skeleton {
	height: 46px;
	width: 160px;
}

button.reload {
	background: var(--white);
	border-radius: 96px;
	height: 96px;
	width: 96px;
}

button.reload:hover {
	background: var(--orange);
}

button.reload:hover path {
	fill: var(--white);
}


/* Inputs */

input[type=text],
input[type=email],
input[type=password],
input[type=search],
input[type=number],
textarea {
	background: var(--card-background);
	border: 1px solid var(--grey);
	border-radius: var(--border-radius-50);
	color: var(--body-text-color);
	display: flex;
	font-size: var(--font-size-125);
	gap: var(--padding-50);
	padding: var(--padding-50) var(--padding);
	transition: var(--transition);
	width: 240px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=number]:focus,
textarea:focus {
	border: 1px solid var(--field-focus-color);
	outline: 3px solid var(--field-outline-color);
	outline-offset: 0;
}

input[type=text]:disabled,
input[type=email]:disabled,
input[type=password]:disabled,
input[type=search]:disabled,
input[type=number]:disabled {
	border: 1px solid var(--cta-disabled-border);
	opacity: .5;
}

input[type=text][aria-invalid=true],
input[type=email][aria-invalid=true],
input[type=password][aria-invalid=true],
input[type=search][aria-invalid=true],
input[type=number][aria-invalid=true],
textarea[aria-invalid=true] {
	border: 1px solid #ec1801;
	outline: 3px solid var(--red-25-transparent);
	color: #ec1801;
}

label[aria-invalid=true] {
	color: #ec1801;
}

textarea {
	min-height: 36px;
	max-width: 100%;
	min-width: 100%;
}


/* Form controls */

.radio-wrapper {
	display: flex;
	flex-direction: column;
	gap: calc(var(--padding-50) + 1px);
}

.radio-button-wrapper {
	align-items: center;
	display: flex;
	gap: var(--padding-50);
}

input[type=radio] {
	-webkit-appearance: none;
	border: 3px solid var(--card-background);
	border-radius: var(--border-radius-300);
	flex: 0;
	min-width: 23px !important;
	height: 23px;
	margin: 0;
	outline: 1px solid var(--grey);
}

input[type=radio]:checked {
	background: var(--icon-button-fill);
}

input[type=checkbox] {
	-webkit-appearance: none;
	background: var(--checkbox-background);
	border-radius: var(--border-radius-300);
	height: 20px;
	margin: 0;
	position: relative;
	overflow: hidden;
	transition: var(--transition);
	transition-timing-function: ease-in-out;
	width: 32px !important;
	max-width: 32px;
	min-width: 32px;
}

input[type=checkbox]:disabled {
	opacity: .5;
}

input[type=checkbox]:disabled+label {
	opacity: .5;
}

input[type=checkbox]:checked {
	background: var(--link-color);
}

input[type=checkbox]::after {
	background: var(--card-background);
	border-radius: var(--border-radius-300);
	box-shadow: 0 1px 1px rgba(0,0,0,.25);
	content: '';
	display: block;
	height: 16px;
	left: 2px;
	width: 16px;
	position: absolute;
	top: 2px;
	transition: var(--transition);
	transition-timing-function: ease-in-out;
}

input[type=checkbox]:checked::after {
	transform: translateX(12px);
}

.checkbox-wrapper {
	align-items: flex-start;
	display: flex;
	gap: var(--padding-50);
	max-width: 300px;
	padding: 13px 0 0 0;
}

.checkbox-wrapper.full-width {
	max-width: unset;
	width: 100%;
}


/* Donut */

.progress-donut-wrapper {
	align-items: center;
	cursor: pointer;
	display: flex;
	gap: var(--padding-50);
}

.progress-donut-wrapper.extra-large {
	gap: var(--padding-150);
	font-weight: bold;
	font-size: var(--font-size-300);
}

.progress-donut {
	border: 4px solid var(--link-color);
	border-radius: 24px;
	height: 24px;
	width: 24px;
}

circle.bar {
	stroke: var(--link-color);
	stroke-linecap: round;
	fill: none;
	transition: 1500ms;
	transition-timing-function: ease-in-out;
}

circle.bar.audio {
	display: none;
}

circle.bar.audio.visible {
	display: block;
}

circle.background {
	stroke: var(--link-highlight-color);
	stroke-linecap: round;
	fill: none;
}

.progress-donut-label {
	color: var(--link-color);
}

.progress-donut-wrapper.complete .progress-donut-label {
	color: var(--turquoise);
	font-weight: bold;
}

.progress-donut-wrapper.unstarted {
	opacity: .5;
}

.progress-donut-wrapper.unstarted .progress-donut-label {
	color: var(--body-text-color);
}

.progress-donut-wrapper.unstarted circle.bar {
	stroke: var(--body-text-color);
}

.progress-donut-wrapper.unstarted circle.background {
	stroke: var(--body-text-color-10-transparent);
}


/* Spinner */

.spinner {
	display: flex;
}

circle.spinner-bar {
	animation: spinning-wheel 1000ms infinite;
	animation-timing-function: linear;
	stroke: var(--link-color);
	stroke-dasharray: 25 100;
	stroke-linecap: round;
	transform: rotate(-90deg);
	transform-origin: center;
	fill: none;
}

@keyframes spinning-wheel {
	0% {
		stroke-dasharray: 25 100;
		transform: rotate(-90deg);
	}
	100% {
		stroke-dasharray: 25 100;
		transform: rotate(270deg);
	}
}

circle.spinner-background {
	stroke: var(--link-highlight-color);
	stroke-linecap: round;
	fill: none;
}

.spinner.black circle.spinner-bar {
	stroke: var(--body-text-color);
}

.spinner.black circle.spinner-background {
	stroke: var(--body-text-color-10-transparent);
}


/* Select search */

.select-search-wrapper {
	position: relative;
	width: 100%;
}

.select-search-dropdown {
	background: var(--card-background);
	border-radius: var(--border-radius-50);
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.10), 0px 10px 20px 1px rgba(0, 0, 0, 0.25);
	display: none;
	list-style: none;
	margin: 0;
	max-height: 120px;
	overflow-x: scroll;
	padding: var(--padding-25);
	position: absolute;
	top: 46px;
	width: 100%;
}

.select-search-dropdown.visible {
	display: block;
}

.select-search-dropdown li {
	border-radius: var(--border-radius-25);
	cursor: pointer;
	padding: var(--padding-50) var(--padding);
}

.select-search-dropdown li.selected  {
	background: var(--link-highlight-color);
}

/* Signup */ 

.placard.enterprise-signup {
	align-items: center;
	background: transparent;
	flex-direction: column;
  font-size: var(--font-size-125);
	line-height: var(--line-height-125);
	margin-bottom: var(--padding-150);
	padding: 0;
	text-align: center;
}

.placard.enterprise-signup .avatar {
	height: 72px;
	width: 72px;
}


/* User menu */

.enterprise-wrapper a {
	align-items: flex-start !important;
	max-height: unset !important;
	padding: var(--padding-125) var(--padding-125) !important;
}

.enterprise-wrapper.padded {
	align-items: center;
	max-height: unset !important;
	padding: var(--padding) var(--padding-125) !important;
}

.enterprise-wrapper-body {
	display: flex;
	flex-direction: column;
	gap: var(--padding-25);
}

.enterprise-company-name {
	font-weight: bold;
}

.enterprise-manage-text {
	align-items: center;
	display: flex;
	color: var(--link-color);
	gap: var(--padding-25);
}


/* Containers */

.content.enterprise {
	display: flex;
	flex-direction: row;
	min-height: 670px;
	gap: var(--padding-200);
}

.enterprise-body {
	justify-content: flex-start;
	display: flex;
	flex: 1;
	gap: var(--padding-150);
	flex-direction: column;
}

.enterprise-body .card {
	flex: 0;
	height: auto;
	padding: var(--padding-150);
}

.enterprise-body .card.skeleton {
	min-height: 300px;
}

.enterprise-body .card.stat.skeleton {
	min-height: 104px;
}

.enterprise-body .card.stat {
	gap: var(--padding-50);
}

.figure {
	font-size: var(--font-size-300);
	font-weight: bold;
	line-height: var(--font-size-300);
}

.enterprise-body .card.full-height {
	flex: 1;
}

.enterprise-body .card.toolbar {
	align-items: center;
	flex-direction: row;
	min-height: 60px;
	padding: var(--padding);
}

.enterprise-body .card-wrapper .card {
	flex: 1;
}

.enterprise-body.settings {
	gap: var(--padding-150);
}

.enterprise-body.settings .card {
	padding: var(--padding-200);
}


/* Sidebar */

nav.enterprise-sidebar {
	background: var(--card-background-secondary);
	border-radius: var(--border-radius);
	max-height: 670px;
	width: 230px;
}

nav.enterprise-sidebar ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

nav.enterprise-sidebar ul li {	
	border-bottom: 1px solid var(--background-color);
}

nav.enterprise-sidebar li a {
	align-items: center;
	display: flex;
	color: var(--body-text-color);
	gap: var(--padding);
	padding: var(--padding) var(--padding-150);
}

nav.enterprise-sidebar ul li a:hover {
	color: var(--link-color);
}

nav.enterprise-sidebar ul li a:hover path {
	fill: var(--link-color);
}

nav.enterprise-sidebar ul li a.active {	
	font-weight: bold;
}

nav.enterprise-sidebar .avatar-wrapper {
	color: var(--body-text-color);
	padding: var(--padding-150);
}

nav.enterprise-sidebar .avatar-wrapper:hover path {
	fill: var(--link-color);
}


/* Subscription badge */

.subscription-badge {
	background: var(--turquoise-10-transparent);
	border: 1px solid var(--turquoise);
	border-radius: var(--border-radius-50);
	color: var(--turquoise);
	display: inline;
	padding: var(--padding-25) var(--padding-50);
	font-weight: bold;
}


/* Toolbar */

.toolbar-input {
	font-size: var(--font-size) !important;
}

.text-wrapper {
	padding-left: var(--padding-50);
}

.toolbar-group {
	align-items: center;
	display: flex;
	gap: var(--padding);
}


/* Tables */

table.view-table th,
table.view-table td {
	cursor: default;
	text-align: left;
	padding: var(--padding) var(--padding-150);
	vertical-align: center;
	white-space: nowrap;
}

table.wrap th,
table.wrap td {
	white-space: normal;
}

table.view-table tr.linked:hover {
	background: var(--link-highlight-color);
}

table.view-table tr.linked td {
	padding: 0;
}

table.view-table tr.linked td a {
	display: block;
	color: var(--body-text-color);
	padding: var(--padding) var(--padding-150);
}

table.view-table tr.linked:hover td a {
	color: var(--link-color);
}

table.view-table th {
	border-bottom: 3px double var(--card-divider-border);
	font-weight: normal;
	cursor: pointer;
}

table.view-table th.sortable:hover {
	color: var(--link-color);
}

table.view-table th.sortable.active {
	font-weight: bold;
}

table.view-table tr:nth-child(even),
table.view-table tr:nth-child(even) {
	background-color: var(--table-row-even-background);
}

.account-status {
	align-items: center;
	display: flex;
	gap: var(--padding-50);
}

.account-status::before {
	border: 1px solid var(--grey);	
	border-radius: var(--border-radius);
	content: '';
	display: block;
	height: 10px;
	width: 10px;
}

.account-status.active::before {
	background: var(--turquoise);
	border-color: var(--turquoise);
}
